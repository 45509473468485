export default {
  profile: {
    my_account: 'Akunku',
    loyalty_point: 'Loyalitas Poin',
    sign_in_for_point: 'Masuk mendapatkan point',
    my_order: 'Pesananku',
    shipping_address: 'Alamat Kirim',
    change_account: 'Ubah Akunku',
    about_store: 'Tentang Toko',
    chat_store: 'Chat Toko',
    log_out: 'Keluar',
    login_to_store: 'Masuk ke ',
    login: 'Masuk',
    register: 'Daftar',
    login_as_guest: 'Login sebagai Tamu',
    not_have_account_yet: 'Belum punya akun?',
    register_here: 'Daftar di sini',
    my_cart: 'Keranjangku',
    note_loyalty: '*Tidak berlaku untuk masuk sebagai Tamu',
    forget_password: 'Lupa Password',
    enter_email: 'Masukkan email akun Anda',
    use_phone_number: 'Gunakan nomor HP',
    use_email: 'Gunakan Email',
    have_account: 'Sudah punya akun?',
    alert_success_change_photo: 'Berhasil mengganti foto profil!',
    change_photo: 'Ubah foto',
    remove_current_photo: 'Hapus foto saat ini',
    alert_success_remove_photo: 'Berhasil menghapus foto profil!',
    alert_success_change_name: 'Berhasil mengganti nama!',
    customer: 'Pelanggan',
    customer_type: 'Tipe pelanggan'
  },
  loyalty: {
    loyalty_point: 'Loyalitas Poin',
    loyalty_info_1: 'Kumpulkan point dari pembelian produk di toko ini. Tukar point-mu dengan diskon atau gratis produk tertentu. ',
    call_the_store: 'Hubungi toko ',
    loyalty_info_2: 'untuk informasi lebih lanjut.',
    last_acquisition: 'Perolehan point terakhir',
    shop_now_get_point: 'Belanja sekarang dan dapatkan pointnya!',
    last_redemption: 'Penukaran point terakhir',
    never_redemption: 'Belum Pernah menukarkan point',
    points_gain: 'Perolehan Point'
  },
  account: {
    add_shipping_address: 'Tambah Alamat Kirim',
    country: 'Negara',
    city_or_district: 'Kota / Kabupaten',
    sub_district: 'Kecamatan',
    postal_code: 'Kode Pos',
    default_address: 'Tetapkan sebagai alamat default',
    delete_address: 'Hapus Alamat',
    save: 'Simpan',
    change_my_account: 'Ubah Akunku',
    name: 'Nama',
    phone_number: 'Nomor Hp',
    email: 'Email',
    change_password: 'Ubah Password',
    otp_call_info_1: 'Sistem kami akan menghubungi',
    otp_call_info_2: 'Masukkan 4 digit terakhir dari nomor telepon penghubung untuk verifikasi',
    otp_wa_info_1: 'Sistem kami akan mengirimkan 6 kode unik ke nomor ',
    otp_wa_info_2: 'silahkan masukkan 6 digit number untuk verifikasi.',
    miscall_now: 'Oke, miscall sekarang',
    use_otp_whatsapp: 'Gunakan WhatsApp',
    verify_yourself: 'Verifikasi diri Anda',
    verification: 'Verifikasi',
    verify_code_send: 'Kode verifikasi telah dikirim ke',
    use_call: 'Panggilan',
    use_message: 'SMS',
    use_whatsapp: 'WhatsApp',
    resending: 'Kirim Ulang',
    change_name: 'Ubah Nama',
    verification_success: 'Verifikasi Berhasil!',
    continue: 'Lanjutkan',
    change_phone_number: 'Ubah Nomor Telepon',
    new_phone_number: 'Nomor telepon Baru',
    shipping_address: 'Alamat Kirim',
    add: 'Tambah',
    save_address_info: 'Simpan alamat kirim untuk mempermudah proses pemesanan',
    choose_this_location: 'Pilih lokasi ini',
    change_email: 'Ganti Email',
    notif_change_picture: 'Foto berhasil diubah',
    placeholder_shippping_to: 'Nama, Contoh: Rumah',
    address: 'Alamat lengkap',
    province: 'Provinsi',
    save_address: 'Simpan Alamat',
    use_location: 'Gunakan Lokasi',
    google_maps_required: 'Google Maps diperlukan',
    name_required: 'Nama wajib di isi',
    note_required: 'Note wajib di isi',
    recall: 'Hubungi Ulang',
    resend_chat: 'SMS Ulang',
    resend_whatsapp: 'WhatsApp Ulang',
    password: 'Kata Sandi',
    full_address: 'Silahkan isi alamat lengkap anda',
    note_address: 'Informasi lain seperti nomor rumah',
    desc_otp_whatsapp: 'Sistem kami akan mengirimkan kode ke nomor {phone}. Silahkan masukkan 6 digit number untuk verifikasi.',
    desc_otp_miscall: 'Sistem akan miscall {phone}. Masukkan 4 digit terakhir dari nomor telepon penghubung untuk verifikasi.',
    desc_otp_message: 'Sistem kami akan mengirimkan kode ke nomor {phone}. Silahkan masukkan 5 digit number untuk verifikasi.',
    whatsapp_not_been_set: 'Nomor WhatsApp belum ditetapkan',
    cannot_use_same_phone_number: 'Tidak dapat menggunakan nomor telepon yang sama'
  },
  layout: {
    open_soon: 'Segera Buka',
    open: 'Buka',
    close_soon: 'Segera Tutup',
    close: 'Tutup',
    my_account: 'Akunku',
    until: 'sampai',
    store_off: 'Libur',
    all: 'Semua'
  },
  login: {
    sure_change_point: 'Apakah Anda yakin menukarkan point?',
    exchange: 'Tukar',
    sign_in: 'Masuk',
    register: 'Daftar',
    user_varification: 'Sediakan nomor HP untuk melanjutkan masuk sebagai',
    success_login: 'Masuk sebagai ',
    guest: 'tamu',
    failed_login: 'Gagal masuk!'
  },
  merchant: {
    select_branch_store: 'Pilih toko cabang',
    select_branch: 'Pilih cabang',
    branch: 'Cabang',
    branch_not_found: 'Cabang tidak ditemukan',
    open_soon: 'Segera Buka',
    open: 'Buka',
    close_soon: 'Segera Tutup',
    close: 'Tutup',
    merchant_tnc: 'Syarat & Ketentuan Toko',
    other_branch: 'Cabang lainnya',
    question_confirm_move_branch: 'Pindah ke {store}?',
    desc_confirm_move_branch: 'Menu yang dipilih dari tempat sebelumnya akan terhapus.'
  },
  order: {
    my_order: 'Pesananku',
    detail_order: 'Detail Pesanan',
    payment: 'Pembayaran',
    buy_date: 'Tanggal beli',
    shipping_address: 'Alamat Kirim',
    sub_total: 'Subtotal',
    discount: 'Diskon',
    service_cost: 'Biaya Layanan',
    shipping_cost: 'Biaya Pengiriman',
    total_payment: 'Total Pembayaran',
    reload: 'Muat ulang',
    processed: 'Diproses',
    sent: 'Dikirim',
    received: 'Diterima',
    finished: 'Selesai',
    contact_seller: 'Hubungi Penjual',
    view_detail: 'Lihat Detail',
    additional_cost: 'Biaya Tambahan',
    distance_info: 'Jarak melebihi gratis orgkir toko',
    shipping_cost_info: 'pengiriman pesanan dikenakan biaya tambahan',
    from: 'dari ',
    log_order: 'Log Pesanan',
    status: {
      'finished': 'Selesai',
      'sent': 'Dikirim',
      'waiting_for_payment': 'Menunggu pembayaran',
      'waiting_confirmation': 'Menunggu konfirmasi toko',
      'ready_to_pickup': 'Siap diambil',
      'pickup': 'Diambil',
      'cancelled': 'Dibatalkan',
      'is_paid': 'Pembayaran diverifikasi',
      'bought': 'Dibeli',
      'processed': 'Diproses'
    },
    store_location: 'Lokasi Toko',
    repeat: 'Pesan Lagi',
    distance_exceeded: 'Jarak melebihi minimum Gratis Antar',
    free_shipping_additional_cost: 'Pengiriman pesanan akan dikenai biaya tambahan',
    ready_to_pickup: 'Siap diambil',
    picked_up: 'diambil',
    no_products_selected: 'Tdak ada produk yang dipilih',
    number_was_copied_successfully: 'Nomor berhasil disalin',
    number_was_copied_unsuccessfully: 'Nomor tidak berhasil disalin',
    unable_to_copy: 'Oops, tidak dapat menyalin',
    alert_shipper_info_delivery_time: 'Pastikan waktu pengiriman tidak mendekati dengan jam terakhir operasional agar kurir dapat ditemukan dan dapat segera mengirim pesananmu',
    courier_available: 'Tersedia {time}',
    courier_close_in: 'Ditutup dalam {time} menit!',
    hour: 'Jam',
    not_available: 'Tidak tersedia',
    live_tracking: 'Pelacakan langsung',
    shipping_insurance: 'Asuransi pengiriman',
    from_your_location: 'dari lokasi anda',
    insurance: 'Asuransi',
    rounding: 'Pembulatan',
    information_shipper_pending_process: 'Saat ini pengiriman {shipper} tidak tersedia karena sedang di luar jam operasional. Pesanan akan dikirim secepatnya besok.',
    price_includes_tax: 'Harga termasuk pajak',
    arrived: 'Tiba',
    pick_up_operational_days_are: 'Hari operasional pick up adalah',
    terms_of_pick_up_request_time: 'Ketentuan waktu request pick up',
    same_day: 'Hari yang sama',
    next_day: 'Hari berikutnya',
    information: 'Informasi',
    failed_pick_up_actions: 'Tindakan gagal jemput',
    shipping_option_not_selected: 'Untuk melanjutkan, Silahkan pilih opsi pengiriman terlebih dahulu',
    product_option_not_selected: 'Untuk melanjutkan, Silahkan pilih produk terlebih dahulu'
  },
  product: {
    choice_address: 'Pilih Alamat',
    take_from_map: 'Ambil dari map',
    add_shipping_address: 'Tambah Alamat Kirim',
    choose: 'Pilih',
    select_courier: 'Pilih Kurir',
    detail_product: 'Detail Produk',
    little_stock: 'Stok Sedikit',
    description: 'Deskripsi',
    no_description: 'Tidak ada deskripsi',
    related_product: 'Produk Terkait',
    add: 'Tambah',
    buy_now: 'Beli Sekarang',
    free_shipping: 'Gratis Ongkir',
    free: 'Gratis',
    operational_hour: 'Jam Operasional',
    payment_method: 'Metode Pembayaran',
    bank_transfer: 'Transfer Bank',
    pay_now: 'Bayar Sekarang',
    no_data: 'Oops.. produk tidak ditemukan',
    shipping_time: 'Waktu Kirim',
    order_confirmation: 'Konfirmasi Pesanan',
    product: 'Produk',
    price: 'Harga',
    alert_title_qty_check_failed: 'Tambah kuantitas gagal',
    alert_desc_qty_check_failed: 'Kuantitas {product} melebihi dari stok',
    choose_hour: 'Pilih Jam',
    take_time: 'Waktu Ambil',
    choose_time: 'Pilih Waktu',
    take_by_yourself: 'Ambil Sendiri',
    choose_address: 'Pilih Alamat',
    close_soon: 'Segera Tutup',
    pay_on_the_spot: 'Bayar di tempat',
    grab_gojek_info: 'Biaya pengiriman Gojek / Grab akan dibayar pembeli saat produk diterima',
    courier: 'Kurir',
    specify_address: 'Tentukan alamat kirim',
    shipping_cost: 'Biaya Pengiriman',
    total_payment: 'Total Pembayaran',
    pay: 'Bayar',
    shipping_address: 'Alamat Kirim',
    select_retrieve_time: 'Pilih Waktu Ambil',
    date: 'Tanggal',
    hour: 'Jam',
    cancel: 'Batal',
    my_cart: 'Keranjangku',
    sign_in_for_voucher: 'Masuk Untuk gunakan voucher',
    sign_in: 'masuk',
    empty_cart: 'Keranjangmu masih kosong',
    shipping: 'Pengiriman',
    sub_total: 'Subtotal',
    discount: 'Diskon',
    service_cost: 'Biaya Layanan',
    use_vaoucher: 'Pakai Voucher',
    you_save: 'Sipp Kamu hemat',
    buy: 'Beli',
    shop_now: 'Belanja Sekarang',
    apply: 'Terapkan',
    thrifty: 'Hemat',
    delete_product: 'Hapus Produk',
    sold: 'Terjual',
    save: 'Simpan',
    max_buy: 'Mak. Beli ',
    min_buy: 'Min. Beli ',
    remove_market: 'Hapus produk di tandai',
    warm_select_item: 'Anda belum memilih item',
    warm_use_voucher: 'Voucher tidak berlaku untuk login sebagai tamu',
    note_loyalty: '*Tidak berlaku untuk masuk sebagai Tamu',
    input_voucher_code: 'Masukkan kode voucher',
    choose_variant_first: 'Pilih varian terlebih dahulu',
    no_stock: 'Tidak ada stok untuk produk ini',
    buy_out_stock: 'Pembelian melebihi stock',
    choose_pick_up_time: 'Pilih waktu ambil',
    tax_include: 'Harga termasuk pajak',
    tax_exclude: 'Harga tidak termasuk pajak',
    select_courier_shiiping: 'Pilih Kurir Pengiriman',
    new_address: 'Alamat baru',
    free_shipping_tnc: 'Ketentuan Gratis Antar',
    distance_free_shipping: 'Jarak gratis antar',
    min_total_order: 'Minimal total order',
    cost_out_distance: 'Biaya di luar jarak gratis antar',
    max_free_shipping: 'Maksimum jarak antar',
    duration_process_order: 'Durasi Proses Order',
    time_shipping: 'Jam pengantaran',
    discount_name: 'Nama Diskon',
    discount_value: 'Besar diskon',
    max_discount_value: 'Maksimal jumlah diskon',
    min_discount_value: 'Minimal total pesanan',
    usage_limit: 'Batas penggunaan',
    date_use: 'Tanggal berlaku',
    date_expired: 'Tanngal berakhir',
    choose_shipping_time: 'Pilih waktu kirim',
    choose_shipping: 'Pilih Pengiriman',
    merchant_shipping: 'Pengiriman Toko',
    other_shipping: 'Pengiriman Lain',
    fill_address_first: 'Isi alamat kirim terlebih dahulu...',
    later: 'Nanti aja',
    minute: 'Menit',
    from_store: 'dari toko',
    potential_to_get_points: 'Potensi dapat <b>{point}</b> poin',
    add_to_cart: ' Produk berhasil ditambah',
    not_found: 'Oops.. produk tidak ditemukan',
    distance_max: 'Jarak antar max.',
    left_in_stock: 'Stok hanya tersisa {stock}',
    limited_stock: 'Stok Terbatas',
    select_variant_first: 'Pilih varian terlebih dahulu',
    stock_is_not_enough: 'Stok {data} tidak cukup',
    out_of_stock: 'Habis',
    product_combo: 'Produk Paket',
    product_in_combo: 'Produk dalam paket',
    contains_items: 'Berisi {item} item',
    available: 'Tersedia',
    not_available: 'Tidak tersedia',
    stock_left_min: 'Stok: sisa <div class="ml-1 color-ol-orange">{stock}</div>',
    stock_left: 'Stok: <div class="ml-1">{stock}</div>',
    product_combo_options: 'Pilihan Paket Produk',
    invalid_purchase_qty_min: 'Jumlah pembelian tidak sesuai dengan minimal pembelian',
    invalid_purchase_qty_max: 'Jumlah pembelian tidak sesuai dengan maksimal pembelian',
    conv_cost: 'Biaya Kenyamanan',
    brand: 'Merek',
    product_group: 'Produk grup'
  },
  sign_in: {
    register_to_store: 'Daftar ke ',
    verify_info: 'Kode verifikasi telah dikirim ke',
    change_number: 'Ganti Nomer',
    resend: 'Kirim Ulang',
    name: 'Nama',
    phone_number: 'Nomor Hp',
    password_confirmation: 'Konfirmasi Password',
    register: 'Daftar',
    phone_number_required: 'Nomor HP Wajib di Isi'
  },
  component: {
    search_order: 'Cari Pesanan',
    search_product: 'Cari Produk',
    category: 'Kategori',
    buy_date: 'Tanggal Beli',
    special: 'Spesial',
    shipping_address: 'Alamat Kirim',
    select_location: 'Pilih Lokasi',
    next: 'Selanjutnya',
    input_number_link_aja: 'Masukkan nomor handphone Link Aja Anda',
    input_number_ovo: 'Masukkan nomor handphone OVO Anda',
    search: 'Cari',
    is_featured: 'Unggulan',
    is_new_release: 'Rilis baru',
    is_popular: 'Populer',
    is_preorder: 'Pre-order',
    is_sale: 'Sale'
  },
  dialog: {
    bank_transfer: 'Transfer Bank',
    pay_to: 'Bayar ke',
    finish_payment_before: 'Selesaikan pembayaran sebelum',
    detail_order: 'Detail Pesanan',
    cancel_order: 'Batalkan Pesanan',
    are_you_sure: 'Apakah anda yakin ingin menghapus?',
    back: 'Kembali',
    yes_cancel: 'Ya, Batalkan',
    info_credit_card: 'Informasi Kartu Kredit',
    cancel: 'Batalkan',
    yes: 'Ya',
    no: 'Tidak',
    desc_confirm_remove_order: 'Apakah Anda yakin akan menghapus pesanan Anda?',
    close: 'Tutup',
    search_order: 'Cari Pesanan',
    search_product: 'Cari Produk',
    category: 'Kategori',
    special: 'Spesial',
    all: 'Semua',
    superior: 'Unggulan',
    new_release: 'Rilis Terbaru',
    popular: 'Populer',
    pre_order: 'Pre-Order',
    finished: 'Selesai',
    sent: 'Dikirim',
    wait_payment: 'Menunggu Bayar',
    cancelled: 'Dibatalkan',
    failed_order: 'Pesanan Gagal Dibuat!',
    success_order: 'Pesanan Berhasil Dibuat!',
    canceled_order: 'Pesanan dibatalkan',
    your_order_will_processed: 'Pesananmu akan segera diproses penjual',
    sorry_your_order_failed: 'Maaf pesanan kamu gagal diproses',
    sorry_your_order_cancelled: 'Maaf pesanan kamu dibatalkan',
    order_no: 'Nomor Pesanan',
    home: 'Beranda',
    payment_on_process: 'Pembayaran sedang di prosess',
    desc_payment_on_process: 'Pembayaranmu sedang diprosess, tunggu sebentar atau refresh',
    alert_store_off_title: 'Toko sedang libur',
    alert_store_off_description: 'Meskipun begitu, Anda tetap dapat melanjutkan pembelian. Toko kembali buka pada',
    continue_buying: 'Lanjut beli',
    refresh: 'Muat ulang',
    choose_payment: 'Pilih Pembayaran',
    payment_failed: 'Waduh! Pembayaran Gagal',
    desc_payment_failed: 'Pesananmu masih membutuhkan pembayaran. Coba lagi atau pilih metode pembayaran lain',
    confirm: 'Konfirmasi',
    are_you_sure_want_to_cancel_the_order: 'Apakah Anda yakin ingin membatalkan pesanan ini?',
    out_of_range: 'Jarak toko di luar jangkauan',
    failed_to_delete: 'Gagal menghapus!',
    yes_continue: 'Ya, Lanjutkan',
    desc_confirm_delete_picture: 'Apakah Anda yakin ingin menghapus gambar saat ini?'
  },
  home: {
    others: 'lainnya',
    days: 'Hari'
  },
  global: {
    note: 'Catatan',
    self_pick_up: 'Ambil sendiri',
    free_shipping: 'Gratis antar',
    day: {
      monday: 'Senin',
      tuesday: 'Selasa',
      wednesday: 'Rabu',
      thursday: 'Kamis',
      friday: 'Jum\'at',
      saturday: 'Sabtu',
      sunday: 'Minggu'
    },
    date: {
      january: 'Januari',
      february: 'Februari',
      march: 'Maret',
      april: 'April',
      may: 'Mei',
      june: 'Juni',
      july: 'Juli',
      august: 'Agustus',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'Desember',
      sunday: 'Minggu',
      monday: 'Senin',
      tuesday: 'Selasa',
      wednesday: 'Rabu',
      thursday: 'Kamis',
      friday: 'Jumat',
      saturday: 'Sabtu'
    },
    coming_soon: 'Segera hadir',
    error_message_connection_time_out: 'Koneksi bermasalah, silahkan periksa koneksi internet!',
    ok: 'Oke'
  },
  discount: {
    voucher_detail: 'Detail Voucher',
    disc_name: 'Nama diskon',
    amount: 'Besar diskon',
    max_amount: 'Maksimal jumlah diskon',
    min_order: 'Minimal total pesanan',
    usage_limit: 'Batas penggunaan',
    no_limit: 'Tidak dibatasi',
    active_date: 'Tanggal berlaku',
    end_date: 'Tanggal berakhir',
    no_expiry_date: 'Tidak ada',
    valid_for: 'Berlaku untuk',
    valid_until: 'Berlaku sampai',
    valid_on: 'Berlaku pada',
    input_placeholder_voucher_code: 'Kode Voucher',
    valid_for_all_cust_type: 'Semua tipe pelanggan',
    valid_for_all_cust: 'Semua pelanggan',
    valid_for_all_product_group: 'Semua grup produk',
    valid_for_all_products: 'Semua produk',
    valid_for_all_brands: 'Semua merek',
    valid_for_all_product_combos: 'Semua paket'
  },
  v2: {
    complete_the_required_fields: 'Melengkapi data yang diperlukan',
    products_have_not_been_published: 'produk {item} belum dipublikasikan',
    how_to_pay_with_qris: 'Cara bayar dengan QRIS',
    press_the_download_qris_button: 'Tekan tombol \"Download QRIS\" untuk mengunduh gambar QRIS.',
    oppen_an_eWallet_ebanking: 'Buka aplikasi e-Wallet atau e-Banking yang menyediakan pembayaran QRIS.',
    look_for_the_qris_scan_option: 'Cari opsi scan QRIS dari gambar. Pilih gambar QRIS yang sebelumnya di download.',
    after_scanning_the_total_transaction_price_will_appear: 'Setelah discan, maka akan muncul total harga transaksi.',
    confirm_and_pay: 'Konfirmasi dan bayar.',
    you_have_successfully_paid: 'Anda berhasil membayar pesanan.',
    scan_this_qris: 'Scan QRIS ini',
    download_qris: 'Unduh QRIS',
    expired: 'Waktu habis',
    complete_payment_immediately: 'Segera selesaikan pembayaran',
    regenerate_qris_or_use_another_payment_method: 'Buat QRIS ulang atau gunakan metode pembayaran lainnya',
    payment_guide: 'Panduan pembayaran',
    view_qr: 'Lihat QR',
    your_payment_time_has_expired: 'Yah, waktu pembayaran kamu sudah habis! Kamu bisa pesan ulang',
    use_other_payment_method: 'Gunakan metode bayar lain',
    check_payment_status: 'Cek status pembayaran',
    bni_atm: '<li>Masukkan Kartu Anda.</li><li>Pilih Bahasa.</li><li>Masukkan PIN ATM Anda.</li><li>Pilih "Menu Lainnya".</li><li>Pilih "Transfer".</li><li>Pilih Jenis rekening yang akan Anda gunakan (Contoh: "Dari Rekening Tabungan").</li><li>Pilih “Virtual Account Billing”.</li><li>Masukkan nomor Virtual Account Anda (<strong>{va_number}</strong>).</li><li>Konfirmasi, apabila telah sesuai, lanjutkan transaksi.</li><li>Transaksi Anda telah selesai</li>',
    bni_mobile: '<li>Akses BNI Mobile Banking dari handphone kemudian masukkan user ID dan password.</li><li>Pilih menu “Transfer”.</li><li>Pilih menu “Virtual Account Billing” kemudian pilih rekening debet.</li><li>Masukkan nomor Virtual Account Anda (<strong>{va_number}</strong>) pada menu “input baru”.</li><li>Konfirmasi transaksi anda</li><li>Masukkan Password Transaksi.</li><li>Pembayaran Anda Telah Berhasil.</li>',
    bni_internet: '<li>Ketik alamat https://ibank.bni.co.id kemudian klik “Enter” atau<a href=\"https://ibank.bni.co.id\" target=\"_blank\">klik disini</a>.</li><li>Masukkan User ID dan Password.</li><li>Pilih menu “Transfer”</li><li>Pilih “Virtual Account Billing”.</li><li>Kemudian masukan nomor Virtual Account Anda (<strong>{va_number}</strong>) yang hendak dibayarkan. Lalu pilih rekening debet yang akan digunakan. Kemudian tekan “lanjut”</li><li>Periksa ulang mengenai transaksi yang anda ingin lakukan</li><li>Masukkan Kode Otentikasi Token.</li><li>Pembayaran Anda berhasil</li>',
    mandiri_atm: '<li>Masukkan kartu ATM dan pilih \"Bahasa Indonesia\"</li><li>Ketik nomor PIN dan tekan BENAR</li><li>Pilih menu “BAYAR/BELI”</li><li>Pilih menu “MULTI PAYMENT”</li><li>Ketik kode perusahaan, yaitu \"88908\" (Xendit 88908), tekan \"BENAR\"</li><li>Masukkan nomor Virtual Account (<strong>{va_number}</strong>)</li><li>Isi NOMINAL, kemudian tekan \"BENAR\"</li><li>Muncul konfirmasi data customer. Pilih Nomor 1 sesuai tagihan yang akan dibayar, kemudian tekan \"YA\"</li><li>Muncul konfirmasi pembayaran. Tekan \"YA\" untuk melakukan pembayaran</li><li>Bukti pembayaran dalam bentuk struk agar disimpan sebagai bukti pembayaran yang sah dari Bank Mandiri</li><li>Transaksi Anda sudah selesai</li>',
    mandiri_mobile: '<li>Log in Mobile Banking Mandiri Online</li><li>Klik “Icon Menu” di sebelah kiri atas</li><li>Pilih menu “Pembayaran”</li><li>Pilih “Buat Pembayaran Baru”</li><li>Pilih “Multi Payment”</li><li>Pilih Penyedia Jasa “Xendit 88908”</li><li>Pilih “No. Virtual”</li><li>Masukkan no virtual account anda (<strong>{va_number}</strong>) lalu pilih “Tambah Sebagai Nomor Baru”</li><li>Masukkan “Nominal” lalu pilih “Konfirmasi”</li><li>Pilih “Lanjut”</li><li>Muncul tampilan konfirmasi pembayaran</li><li>Scroll ke bawah di tampilan konfirmasi pembayaran lalu pilih “Konfirmasi”</li><li>Masukkan “PIN” dan transaksi telah selesai</li>',
    mandiri_internet: '<li>Kunjungi website Mandiri Internet Banking: <a href=\"https://ibank.bankmandiri.co.id/retail3/\" target=\"_blank\">klik disini</a></li><li>Login dengan memasukkan USER ID dan PIN</li><li>Pilih \"Pembayaran\"</li><li>Pilih \"Multi Payment\"</li><li>Pilih \"No Rekening Anda\"</li><li>Pilih Penyedia Jasa \"Xendit 88908\"</li><li>Pilih \"No Virtual Account\"</li><li>Masukkan nomor Virtual Account anda (<strong>{va_number}</strong>)</li><li>Masuk ke halaman konfirmasi 1</li><li>Apabila benar/sesuai, klik tombol tagihan TOTAL, kemudian klik \"LANJUTKAN\"</li><li>Masuk ke halaman konfirmasi 2</li><li>Masukkan Challenge Code yang dikirimkan ke Token Internet Banking Anda, kemudian klik \"Kirim\"</li><li>Anda akan masuk ke halaman konfirmasi jika pembayaran telah selesai</li>',
    bri_atm: '<li>Pilih menu Transaksi Lain</li><li>Pilih menu Lainnya</li><li>Pilih menu Pembayaran</li><li>Pilih menu Lainnya</li><li>Pilih menu BRIVA</li><li>Masukkan Nomor BRI Virtual Account (<strong>{va_number}</strong>), lalu tekan “Benar”</li><li>Konfirmasi pembayaran, tekan “Ya” bila sesuai</li>',
    bri_mobile: '<li>Log in ke Mobile Banking</li><li>Pilih menu Pembayaran</li><li>Pilih menu BRIVA</li><li>Masukkan nomor BRI Virtual Account (<strong>{va_number}</strong>) dan jumlah pembayaran</li><li>Masukkan nomor PIN anda</li><li>Tekan “OK” untuk melanjutkan transaksi</li><li>Transaksi berhasil</li><li>SMS konfirmasi akan masuk ke nomor telepon anda</li>',
    bri_internet: '<li>Masukan User ID dan Password</li><li>Pilih menu Pembayaran</li><li>Pilih menu BRIVA</li><li>Pilih rekening Pembayar</li><li>Masukkan Nomor Virtual Account BRI Anda (<strong>{va_number}</strong>)</li><li>Masukkan nominal yang akan dibayar</li><li>Masukkan password dan Mtoken anda</li>',
    permata_atm: '<li>Pada menu utama, pilih transaksi lain</li><li>Pilih Pembayaran Transfer</li><li>Pilih pembayaran lainnya</li><li>Pilih pembayaran Virtual Account</li><li>Masukkan nomor virtual account anda (<strong>{va_number}</strong>)</li><li>Pada halaman konfirmasi, akan muncul nominal yang dibayarkan, nomor, dan nama merchant, lanjutkan jika sudah sesuai</li><li>Pilih sumber pembayaran anda dan lanjutkan</li><li>Transaksi anda selesai</li><li>Ketika transaksi anda sudah selesai, invoice anda akan diupdate secara otomatis. Ini mungkin memakan waktu hingga 5 menit.</li>',
    permata_mobile_x: '<li>Buka Permata Mobile X dan Login</li><li>Pilih Pay \"Pay Bills\"/ \"Pembayaran Tagihan\"</li><li>Pilih “Virtual Account”</li><li>Masukkan Nomor Virtual Account anda (<strong>{va_number}</strong>)</li><li>Detail pembayaran anda akan muncul di layar</li><li>Nominal yang ditagihkan akan muncul di layar. Pilih sumber pembayaran</li><li>Konfirmasi transaksi anda</li><li>Masukkan kode response token anda</li><li>Transaksi anda telah selesai</li><li>Ketika transaksi anda sudah selesai, invoice anda akan diupdate secara otomatis. Ini mungkin memakan waktu hingga 5 menit.</li>',
    permata_mobile: '<li>Buka Permata Mobile dan Login</li><li>Pilih Pay \"Pay Bills\"/ \"Pembayaran Tagihan\"</li><li>Pilih menu “Transfer”</li><li>Pilih sumber pembayaran</li><li>Pilih “daftar tagihan baru”</li><li>Masukan nomor Virtual Account Anda (<strong>{va_number}</strong>)</li><li>Periksa ulang mengenai transaksi yang anda ingin lakukan</li><li>Konfirmasi transaksi anda</li><li>Masukkan SMS token respons</li><li>Pembayaran Anda berhasil</li><li>Ketika transaksi anda sudah selesai, invoice anda akan diupdate secara otomatis. Ini mungkin memakan waktu hingga 5 menit.</li>',
    permata_internet: '<li>Buka situs https://new.permatanet.com atau <a href=\"https://new.permatanet.com\" target=\"_blank\">klik disini</a> dan login</li><li>Pilih menu “pembayaran”.</li><li>Pilih menu “Pembayaran Tagihan”.</li><li>Pilih “Virtual Account”</li><li>Pilih sumber pembayaran</li><li>Pilih menu \"Masukkan Daftar Tagihan Baru\"</li><li>Masukan nomor Virtual Account Anda (<strong>{va_number}</strong>)</li><li>Konfirmasi transaksi anda</li><li>Masukkan SMS token response</li><li>Pembayaran Anda berhasil</li><li>Ketika transaksi anda sudah selesai, invoice anda akan diupdate secara otomatis. Ini mungkin memakan waktu hingga 5 menit</li>',
    sahabat_atm: '<li>Pilih menu Transaksi Lainnya</li><li>Pilih menu Transfer</li><li>Pilih menu Transfer ke Bank Sahabat Sampoerna</li><li>Masukkan nomor Virtual Account (<strong>{va_number}</strong>), lalu tekan “Benar”</li><li>Cek kembali transaksi Anda</li><li>Konfirmasi pembayaran, tekan “Ya” bila sesuai</li>',
    sahabat_mobile: '<li>Log in ke Mobile Banking</li><li>Pilih menu Transfer Dana</li><li>Pilih menu Transfer ke Antar Bank</li><li>Masukkan kode Bank Sahabat Sampoerna yaitu \"523\"</li><li>Masukkan nomor Virtual Account (<strong>{va_number}</strong>) dan jumlah pembayaran</li><li>Masukkan token M-Banking Anda</li><li>Transaksi berhasil</li>',
    sahabat_internet: '<li>Login akun Internet Banking</li><li>Pilih menu Transfer Dana</li><li>Pilih menu Transfer ke Rekening Bank Sahabat Sampoerna</li><li>Masukkan Nomor Virtual Account (<strong>{va_number}</strong>)</li><li>Masukkan nominal yang akan dibayar</li><li>Masukkan token i-Banking Anda</li>',
    bca_mobile: '<li>Buka Aplikasi BCA Mobile</li><li>Pilih \"m-BCA\", kemudian pilih \"m-Transfer\"</li><li>Pilih \"BCA Virtual Account\"</li><li>Masukkan nomor Virtual Account <strong>{va_number}</strong>, lalu pilih \"OK\"</li><li>Klik tombol \"Send\" yang berada di sudut kanan atas aplikasi untuk melakukan transfer</li><li>Klik \"OK\" untuk melanjutkan pembayaran</li><li>Masukkan PIN Anda untuk meng-otorisasi transaksi</li><li>Setelah transaksi pembayaran Anda selesai, invoice ini akan diperbarui secara otomatis. Ini bisa memakan waktu hingga 5 menit.</li>',
    bca_atm: '<li>Masukkan kartu ATM dan PIN BCA Anda</li><li>Di menu utama, pilih \"Transaksi Lainnya\". Pilih \"Transfer\". Pilih \"Ke BCA Virtual Account\"</li><li>Masukkan nomor Virtual Account <strong>{va_number}</strong></li><li>Pastikan data Virtual Account Anda benar, kemudian masukkan angka yang perlu Anda bayarkan, kemudian pilih \"Benar\"</li><li>Cek dan perhatikan konfirmasi pembayaran dari layar ATM, jika sudah benar pilih \"Ya\", atau pilih \"Tidak\" jika data di layar masih salah</li><li>Transaksi Anda sudah selesai. Pilih \"Tidak\" untuk tidak melanjutkan transaksi lain</li><li>Setelah transaksi pembayaran Anda selesai, invoice ini akan diperbarui secara otomatis. Ini bisa memakan waktu hingga 5 menit.</li>',
    bca_internet: '<li>Login ke KlikBCA Individual</li><li>Pilih \"Transfer\", kemudian pilih \"Transfer ke BCA Virtual Account\"</li><li>Masukkan nomor Virtual Account <strong>{va_number}</strong></li><li>Pilih \"Lanjutkan\" untuk melanjutkan pembayaran</li><li>Masukkan \"RESPON KEYBCA APPLI 1\" yang muncul pada Token BCA Anda, lalu klik tombol \"Kirim\"</li><li>Transaksi Anda sudah selesai</li><li>Setelah transaksi pembayaran Anda selesai, invoice ini akan diperbarui secara otomatis. Ini bisa memakan waktu hingga 5 menit.</li>'
  }
}
