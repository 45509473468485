import Vue from 'vue'
import VueSocketIO from 'vue-socket.io'
// import SocketIO from 'socket.io-client'

// const options = { path: '/response' }
// Vue.use(new VueSocketIO({
//   debug: true,
//   connection: SocketIO('http://13.251.0.172:1812', options)
// }))
Vue.use(new VueSocketIO({
  debug: true,
  connection: 'https://olserapayment.indociti.com:1812'
}))
