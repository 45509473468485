export default {
  profile: {
    my_account: 'My Account',
    loyalty_point: 'Loyalty Point',
    sign_in_for_point: 'Sign In to get Point',
    my_order: 'My Order',
    shipping_address: 'Shipping Address',
    change_account: 'Edit Account',
    about_store: 'About Store',
    chat_store: 'Chat Store',
    log_out: 'Log out',
    login_to_store: 'Sign In to ',
    login: 'Sign In',
    register: 'Sign Up',
    login_as_guest: 'Login as Guest',
    not_have_account_yet: 'Don\'t have an account yet?',
    register_here: 'Register here',
    my_cart: 'My Cart',
    note_loyalty: '*Not valid for logging in as a Guest',
    forget_password: 'Forget Password',
    enter_email: 'Enter your account email',
    use_phone_number: 'Use Phone Number',
    use_email: 'Use E-mail',
    have_account: 'Already have an account?',
    alert_success_change_photo: 'Successfully change to photo profile!',
    change_photo: 'Change photo',
    remove_current_photo: 'Remove current photo',
    alert_success_remove_photo: 'Successfully deleted profile picture!',
    alert_success_change_name: 'Successfully changed the name!',
    customer: 'Customer',
    customer_type: 'Customer type'
  },
  loyalty: {
    loyalty_point: 'Loyalty Point',
    loyalty_info_1: 'Collect points from purchasing products at this shop. Exchange your points for a certain discount or free product. ',
    call_the_store: 'Contact the shop ',
    loyalty_info_2: 'for more information.',
    last_acquisition: 'Last point acquisition',
    shop_now_get_point: 'Shop now and get the points!',
    last_redemption: 'Last point redemption',
    never_redemption: 'Never redeemed points',
    points_gain: 'Points Gain'
  },
  account: {
    add_shipping_address: 'Add Shipping Address',
    country: 'Country',
    city_or_district: 'City / District',
    sub_district: 'Sub District',
    postal_code: 'Postal Code',
    default_address: 'Set as the default address',
    delete_address: 'Delete Address',
    save: 'Save',
    change_my_account: 'Edit My Account',
    name: 'Name',
    phone_number: 'Phone Number',
    email: 'Email',
    change_password: 'Change Password',
    otp_call_info_1: 'Our system will miscall',
    otp_call_info_2: 'Enter the last 4 digits of the contact number for verification',
    otp_wa_info_1: 'Our system will send 6 unique codes to the number ',
    otp_wa_info_2: 'Please enter the 6 digit number for verification.',
    miscall_now: 'Okay, call now',
    use_otp_whatsapp: 'Use WhatsApp',
    verify_yourself: 'Verify yourself',
    verification: 'Verification',
    verify_code_send: 'A verification code has been sent to',
    use_call: 'Call',
    use_message: 'SMS',
    use_whatsapp: 'WhatsApp',
    resending: 'Resending',
    change_name: 'Change Name',
    verification_success: 'Verification Success!',
    continue: 'Continue',
    change_phone_number: 'Change Phone Number',
    new_phone_number: 'New Phone Number',
    shipping_address: 'Shipping Address',
    add: 'Add',
    save_address_info: 'Save your shipping address to make the ordering process easier',
    choose_this_location: 'Choose this location',
    change_email: 'Change Email',
    notif_change_picture: 'Photo changed successfully',
    placeholder_shippping_to: 'Name, Ex: Home',
    address: 'Address',
    province: 'Province',
    save_address: 'Save Address',
    use_location: 'Use Location',
    google_maps_required: 'Google Maps Required',
    name_required: 'Name Required',
    note_required: 'Note Required',
    recall: 'Recall',
    resend_chat: 'Resend Chat',
    resend_whatsapp: 'Resend WhatsApp',
    password: 'Password',
    full_address: 'Please fill in your full address',
    note_address: 'Other information such as house number',
    desc_otp_whatsapp: 'Our system will send a code to {phone}. Please enter the 6 digit number for verification.',
    desc_otp_miscall: 'The system will miscall {phone}. Enter the last 4 digits of the contact phone number for verification.',
    desc_otp_message: 'Our system will send a code to {phone}. Please enter the 5 digit number for verification.',
    whatsapp_not_been_set: 'WhatsApp number has not been set',
    cannot_use_same_phone_number: 'Cannot use the same phone number'
  },
  layout: {
    open_soon: 'Open soon',
    open: 'Open',
    close_soon: 'Close soon',
    close: 'Closed',
    my_account: 'My Account',
    until: 'until',
    store_off: 'Off',
    all: 'All'
  },
  login: {
    sure_change_point: 'Are you sure to redeem points?',
    exchange: 'Exchange',
    sign_in: 'Sign In',
    register: 'Register',
    user_varification: 'Provide a mobile number to continue logging in as',
    success_login: 'Logged in as ',
    guest: 'guest',
    failed_login: 'Login failed!'
  },
  merchant: {
    select_branch_store: 'Select a branch store',
    select_branch: 'Select a branch store',
    branch: 'Branch store',
    branch_not_found: 'Branch store not found',
    open_soon: 'Open soon',
    open: 'Open',
    close_soon: 'Close soon',
    close: 'Close',
    merchant_tnc: 'Store Terms & Conditions',
    other_branch: 'Other branches',
    question_confirm_move_branch: 'Moving to {store}?',
    desc_confirm_move_branch: 'The selected menu from the previous store will be deleted.'
  },
  order: {
    my_order: 'My Order',
    detail_order: 'Detail Order',
    payment: 'Payment',
    buy_date: 'Buy Date',
    shipping_address: 'Shipping Address',
    sub_total: 'Sub-total',
    discount: 'Discount',
    service_cost: 'Service Fee',
    shipping_cost: 'Shipping costs',
    total_payment: 'Total Payment',
    reload: 'Reload',
    processed: 'Processed',
    sent: 'Sent',
    received: 'Received',
    finished: 'Finished',
    contact_seller: 'Contact Seller',
    view_detail: 'View Detail',
    additional_cost: 'Additional Cost',
    distance_info: 'The distance exceeds the free delivery shop',
    shipping_cost_info: 'Order delivery incurs an additional fee',
    from: 'from ',
    log_order: 'Log Order',
    status: {
      'finished': 'Finished',
      'sent': 'Sent',
      'waiting_for_payment': 'Waiting for Payment',
      'waiting_confirmation': 'Waiting Store Confirmation',
      'ready_to_pickup': 'Ready to Pickup',
      'pickup': 'Pickup',
      'cancelled': 'Cancelled',
      'is_paid': 'Payment verified',
      'bought': 'Bought',
      'processed': 'Processed'
    },
    store_location: 'Store Location',
    repeat: 'Repeat Order',
    distance_exceeded: 'The distance exceeded minimum Free Shipping',
    free_shipping_additional_cost: 'Order delivery will incur an additional charge',
    ready_to_pickup: 'Ready to pickup',
    picked_up: 'Picked up',
    no_products_selected: 'No products selected',
    number_was_copied_successfully: 'Number was copied successfully',
    number_was_copied_unsuccessfully: 'Number was copied unsuccessfully',
    unable_to_copy: 'Oops, unable to copy',
    alert_shipper_info_delivery_time: 'Make sure the delivery time is not close to the last operational hour so that the courier can be found and can send your order',
    courier_available: 'Available {time}',
    courier_close_in: 'Closed in {time} minutes!',
    hour: 'Hour',
    not_available: 'Not available',
    live_tracking: 'Live tracking',
    shipping_insurance: 'Shipping insurance',
    from_your_location: 'from your location',
    insurance: 'Insurance',
    rounding: 'Rounding',
    information_shipper_pending_process: '{shipper} delivery is currently unavailable as it is out of operating hours. Orders will be shipped as soon as possible tomorrow.',
    price_includes_tax: 'Price includes tax',
    arrived: 'Arrived on',
    pick_up_operational_days_are: 'Pick up operational days are',
    terms_of_pick_up_request_time: 'Terms of pick up request time',
    same_day: 'Same day',
    next_day: 'Next day',
    information: 'Information',
    failed_pick_up_actions: 'Failed pick up actions',
    shipping_option_not_selected: 'To proceed, please select the shipping option first',
    product_option_not_selected: 'To proceed, please select the product first'
  },
  product: {
    choice_address: 'Choose Address',
    take_from_map: 'Take from Map',
    add_shipping_address: 'Add Shipping Address',
    choose: 'Choose',
    select_courier: 'Select Courier',
    detail_product: 'Detail Product',
    little_stock: 'Little Stock',
    description: 'Description',
    no_description: 'No Description',
    related_product: 'Related Product',
    add: 'Add',
    buy_now: 'Buy Now',
    free_shipping: 'Free Shipping',
    free: 'Free',
    operational_hour: 'Operational Hour',
    payment_method: 'Payment Method',
    bank_transfer: 'Bank Transfer',
    pay_now: 'Pay Now',
    no_data: 'Oops... no product was found',
    shipping_time: 'Shipping Time',
    order_confirmation: 'Order Confirmation',
    product: 'Product',
    price: 'Price',
    alert_title_qty_check_failed: 'Failed to add quantity',
    alert_desc_qty_check_failed: 'quantity of {nama produk} exceeds the stock',
    choose_hour: 'Choose Hour',
    take_time: 'Take Time',
    choose_time: 'Choose Time',
    take_by_yourself: 'Self Pickup',
    choose_address: 'Choose Address',
    close_soon: 'Close Soon',
    pay_on_the_spot: 'Pay on the Spot',
    grab_gojek_info: 'The Gojek / Grab delivery fee will be paid by the buyer when the product is received',
    courier: 'Courier',
    specify_address: 'Specify a mailing address',
    shipping_cost: 'Shipping Cost',
    total_payment: 'Total Payment',
    pay: 'Pay',
    shipping_address: 'Shipping Address',
    select_retrieve_time: 'Select Retrieve Time',
    date: 'Date',
    hour: 'Hour',
    cancel: 'Cancel',
    my_cart: 'My Cart',
    sign_in_for_voucher: 'Enter to use the voucher',
    sign_in: 'Sign In',
    empty_cart: 'Your basket is still empty',
    shipping: 'Shipping',
    sub_total: 'Sub-total',
    discount: 'Discount',
    service_cost: 'Service Fee',
    use_vaoucher: 'Use the Voucher',
    you_save: 'Sipp you save',
    buy: 'Buy',
    shop_now: 'Shop Now',
    apply: 'Apply',
    thrifty: 'Save',
    delete_product: 'Remove Products',
    sold: 'Sold',
    save: 'Save',
    max_buy: 'Max Buy ',
    min_buy: 'Min Buy ',
    remove_market: 'Delete checked product',
    warm_select_item: 'You haven\'t selected an item',
    warm_use_voucher: 'Voucher is not valid for logging in as a guest',
    note_loyalty: '*Not valid for logging in as a Guest',
    input_voucher_code: 'Input voucher code',
    choose_variant_first: 'Choose the variant first',
    no_stock: 'no stock for this product',
    buy_out_stock: 'Purchases out of stock',
    choose_pick_up_time: 'Choose pick up time',
    tax_include: 'Tax include in price',
    tax_exclude: 'Price does not include tax',
    select_courier_shiiping: 'Choose Shipping Courier',
    new_address: 'New Address',
    free_shipping_tnc: 'Free Delivery Terms',
    distance_free_shipping: 'Free distance between',
    min_total_order: 'Minimum total order',
    cost_out_distance: 'Charges beyond the free delivery distance',
    max_free_shipping: 'Maximum free delivery',
    duration_process_order: 'Order Process Duration',
    time_shipping: 'Delivery time',
    discount_name: 'Discount Name',
    discount_value: 'Big discount',
    max_discount_value: 'Maximum amount of discount',
    min_discount_value: 'Minimum order total',
    usage_limit: 'Usage limit',
    date_use: 'Effective date',
    date_expired: 'Date ends',
    choose_shipping_time: 'Choose delivery time',
    choose_shipping: 'Choose shipping',
    merchant_shipping: 'Merchant Shipping',
    other_shipping: 'Other Shipping',
    fill_address_first: 'Fill in the mailing address first...',
    later: 'Later',
    minute: 'Minute',
    from_store: 'from store',
    potential_to_get_points: 'Potential to get <b>{point}</b> points',
    add_to_cart: ' Product added successfully',
    not_found: 'Oops.. product not found',
    distance_max: 'Distance max.',
    left_in_stock: 'Only {stock} left in stock',
    limited_stock: 'Limited Stock',
    select_variant_first: 'Select a variant first',
    stock_is_not_enough: '{data} stock is not enough',
    out_of_stock: 'Out of stock',
    product_combo: 'Product Combo',
    product_in_combo: 'Product in combo',
    contains_items: 'Contains {item} items',
    available: 'Available',
    not_available: 'Not available',
    stock_left_min: '<div>Stock: </div><div class="ml-1 color-ol-orange">left {stock}</div>',
    stock_left: '<div>Stock: </div><div class="ml-1">{stock}</div>',
    product_combo_options: 'Product Combo Options',
    invalid_purchase_qty_min: 'Quantity does not match the minimum purchase',
    invalid_purchase_qty_max: 'Quantity does not match the maximum purchase',
    conv_cost: 'Convenience fee',
    brand: 'Brand',
    product_group: 'Product group'
  },
  sign_in: {
    register_to_store: 'Register to ',
    verify_info: 'A verification code has been sent to',
    change_number: 'Change Number',
    resend: 'Resend',
    name: 'Name',
    phone_number: 'Phone Number',
    password_confirmation: 'Password Confirmation',
    register: 'Register',
    phone_number_required: 'Mobile Number Required'
  },
  component: {
    search_order: 'Search Order',
    search_product: 'Search Product',
    category: 'Category',
    buy_date: 'Buy Date',
    special: 'Special',
    shipping_address: 'Shipping Address',
    select_location: 'Select Location',
    next: 'Next',
    input_number_link_aja: 'Enter your Link Aja mobile number',
    input_number_ovo: 'Enter your OVO mobile number',
    search: 'Search',
    is_featured: 'Featured',
    is_new_release: 'New release',
    is_popular: 'Popular',
    is_preorder: 'Pre-order',
    is_sale: 'Sale'
  },
  dialog: {
    bank_transfer: 'Bank Transfer',
    pay_to: 'Pay to',
    finish_payment_before: 'Finish payment before',
    detail_order: 'Detail Order',
    cancel_order: 'Cancel Order',
    are_you_sure: 'Are you sure want to delete?',
    back: 'Back',
    yes_cancel: 'Yes, Cancel',
    info_credit_card: 'Credit Card Information',
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No',
    desc_confirm_remove_order: 'Are you sure to remove your order?',
    close: 'Close',
    search_order: 'Search Order',
    search_product: 'Search Product',
    category: 'Category',
    special: 'Special',
    all: 'All',
    superior: 'Superior',
    new_release: 'New Release',
    popular: 'Popular',
    pre_order: 'Pre-Order',
    finished: 'Finished',
    sent: 'Sent',
    wait_payment: 'Waiting for Payment',
    cancelled: 'Cancelled',
    failed_order: 'Order Failed!',
    success_order: 'Order Successfully Created!',
    canceled_order: 'Order Cancelled!',
    your_order_will_processed: 'Your order will be processed immediately by the seller',
    sorry_your_order_failed: 'Sorry, your order failed',
    sorry_your_order_cancelled: 'Sorry, your order cancelled',
    order_no: 'Order Number',
    home: 'Home',
    payment_on_process: 'Payment is in progress',
    desc_payment_on_process: 'Your payment is being processed, wait a moment or refresh',
    alert_store_off_title: 'Sorry, we are close today',
    alert_store_off_description: 'However, you may continue the purchase. Stores reopen on',
    continue_buying: 'Continue buying',
    refresh: 'Refresh',
    choose_payment: 'Select Payment',
    payment_failed: 'Wow! Payment Failed',
    desc_payment_failed: 'Your order still requires payment. Try again or choose another payment method',
    confirm: 'Confirm',
    are_you_sure_want_to_cancel_the_order: 'Are you sure want to cancel this order?',
    out_of_range: 'the distance of outlet is out of range',
    failed_to_delete: 'Failed to delete!',
    yes_continue: 'Yes, Continue',
    desc_confirm_delete_picture: 'Are you sure you want to delete the current photo?'
  },
  home: {
    others: 'others',
    days: 'Days'
  },
  global: {
    note: 'Note',
    self_pick_up: 'Self pick up',
    free_shipping: 'Free shipping',
    day: {
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday'
    },
    date: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
      sunday: 'Sunday',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday'
    },
    coming_soon: 'Coming soon',
    error_message_connection_time_out: 'Connection problem, please check your internet connection!',
    ok: 'Ok'
  },
  discount: {
    voucher_detail: 'Voucher Detail',
    disc_name: 'Discount name',
    amount: 'Discount amount',
    max_amount: 'Discount max',
    min_order: 'Minimum order',
    usage_limit: 'Usage limit',
    no_limit: 'No limit',
    active_date: 'Active date',
    end_date: 'Expiry date',
    no_expiry_date: 'No expiry date',
    valid_for: 'Valid for',
    valid_until: 'Valid until',
    valid_on: 'Valid on',
    input_placeholder_voucher_code: 'Voucher Code',
    valid_for_all_cust_type: 'All customer types',
    valid_for_all_cust: 'All customer',
    valid_for_all_product_group: 'All product groups',
    valid_for_all_products: 'All products',
    valid_for_all_brands: 'All brands',
    valid_for_all_product_combos: 'All combos'
  },
  v2: {
    complete_the_required_fields: 'Complete the required fields',
    products_have_not_been_published: '{item} products have not been published',
    how_to_pay_with_qris: 'How to pay with QRIS',
    press_the_download_qris_button: 'Press the \"Download QRIS\" button to download the QRIS image.',
    oppen_an_eWallet_ebanking: 'Open an e-Wallet or e-Banking application that provides QRIS payments.',
    look_for_the_qris_scan_option: 'Look for the QRIS scan option from the image. Select the QRIS image that was previously downloaded.',
    after_scanning_the_total_transaction_price_will_appear: 'After scanning, the total transaction price will appear.',
    confirm_and_pay: 'Confirm and pay.',
    you_have_successfully_paid: 'You have successfully paid for the order.',
    scan_this_qris: 'Scan this QRIS',
    download_qris: 'Download QRIS',
    expired: 'Expired',
    complete_payment_immediately: 'Complete payment immediately',
    regenerate_qris_or_use_another_payment_method: 'Regenerate QRIS or use another payment method',
    payment_guide: 'Payment guide',
    view_qr: 'View QR',
    your_payment_time_has_expired: 'Well, your payment time has expired! You can reorder',
    use_other_payment_method: 'Use another payment method',
    check_payment_status: 'Check payment status',
    bni_atm: '<li>Insert your card.</li><li>Select your language.</li><li>Enter your ATM PIN.</li><li>Select \"Other Menu\".</li><li>Select \"Transfer\".</li><li>Select the account type you want to use (Example: \"From Savings Account\").</li><li>Select “Virtual Account Billing”.</li><li>Enter your Virtual Account number (<strong>{va_number}</strong>).</li><li>Confirm and proceed with the transaction if everything is correct.</li><li>Your transaction is complete.</li>',
    bni_mobile: '<li>Access BNI Mobile Banking from your phone, then enter your user ID and password.</li><li>Select the “Transfer” menu.</li><li>Choose the “Virtual Account Billing” menu and select a debit account.</li><li>Enter your Virtual Account number (<strong>{va_number}</strong>) in the “new input” menu.</li><li>Confirm your transaction.</li><li>Enter your Transaction Password.</li><li>Your payment has been successful.</li>',
    bni_internet: '<li>Type https://ibank.bni.co.id in your browser, then press “Enter” or <a href=\"https://ibank.bni.co.id\" target=\"_blank\">click here</a>.</li><li>Enter your User ID and Password.</li><li>Select the “Transfer” menu.</li><li>Choose “Virtual Account Billing”.</li><li>Then enter your Virtual Account number (<strong>{va_number}</strong>) to be paid. Select the debit account to be used. Then click “Next”.</li><li>Review the transaction details you want to perform.</li><li>Enter the Token Authentication Code.</li><li>Your payment is successful.</li>',
    mandiri_atm: '<li>Insert your ATM card and select \"Bahasa Indonesia\"</li><li>Enter your PIN and press OK</li><li>Select the “PAY/BUY” menu</li><li>Choose the “MULTI PAYMENT” option</li><li>Enter the company code \"88908\" (Xendit 88908), then press \"OK\"</li><li>Input your Virtual Account number (<strong>{va_number}</strong>)</li><li>Enter the AMOUNT, then press \"OK\"</li><li>A confirmation of customer data will appear. Select option 1 for the bill to be paid, then press \"YES\"</li><li>A payment confirmation will appear. Press \"YES\" to proceed with the payment</li><li>The receipt should be kept as an official proof of payment from Bank Mandiri</li><li>Your transaction is complete</li>',
    mandiri_mobile: '<li>Log in to Mandiri Online Mobile Banking</li><li>Click the “Menu Icon” in the top left corner</li><li>Select the “Payment” menu</li><li>Choose “Create New Payment”</li><li>Select “Multi Payment”</li><li>Choose the Service Provider “Xendit 88908”</li><li>Select “Virtual Number”</li><li>Enter your virtual account number (<strong>{va_number}</strong>) and choose “Add As New Number”</li><li>Enter the “Amount” and select “Confirm”</li><li>Click “Continue”</li><li>The payment confirmation screen will appear</li><li>Scroll down on the payment confirmation screen and select “Confirm”</li><li>Enter your “PIN” and the transaction is complete</li>',
    mandiri_internet: '<li>Visit the Mandiri Internet Banking website: <a href=\"https://ibank.bankmandiri.co.id/retail3/\" target=\"_blank\">click here</a></li><li>Log in by entering your USER ID and PIN</li><li>Select \"Payment\"</li><li>Select \"Multi Payment\"</li><li>Select \"Your Account Number\"</li><li>Select Service Provider \"Xendit 88908\"</li><li>Select \"Virtual Account Number\"</li><li>Enter your Virtual Account number (<strong>{va_number}</strong>)</li><li>Proceed to confirmation page 1</li><li>If correct, click the total amount button, then click \"CONTINUE\"</li><li>Proceed to confirmation page 2</li><li>Enter the Challenge Code sent to your Internet Banking Token, then click \"Send\"</li><li>You will be directed to the confirmation page when the payment is complete</li>',
    bri_atm: '<li>Select the \"Other Transactions\" menu</li><li>Select the \"Other\" menu</li><li>Select the \"Payment\" menu</li><li>Select the \"Other\" menu</li><li>Select the \"BRIVA\" menu</li><li>Enter your BRI Virtual Account Number (<strong>{va_number}</strong>), then press \"Correct\"</li><li>Confirm the payment, press \"Yes\" if everything is correct</li>',
    bri_mobile: '<li>Log in to Mobile Banking</li><li>Select the Payment menu</li><li>Select the BRIVA menu</li><li>Enter your BRI Virtual Account number (<strong>{va_number}</strong>) and payment amount</li><li>Enter your PIN number</li><li>Press “OK” to proceed with the transaction</li><li>Transaction successful</li><li>A confirmation SMS will be sent to your phone number</li>',
    bri_internet: '<li>Enter your User ID and Password</li><li>Select the Payment menu</li><li>Select the BRIVA menu</li><li>Select the Payer\'s account</li><li>Enter your BRI Virtual Account Number (<strong>{va_number}</strong>)</li><li>Enter the amount to be paid</li><li>Enter your password and Mtoken</li>',
    permata_atm: '<li>On the main menu, select \'Other Transactions\'</li><li>Select \'Payment Transfer\'</li><li>Select \'Other Payments\'</li><li>Select \'Virtual Account Payment\'</li><li>Enter your virtual account number (<strong>{va_number}</strong>)</li><li>On the confirmation page, the payment amount, number, and merchant name will appear. Proceed if everything is correct</li><li>Select your payment source and continue</li><li>Your transaction is complete</li><li>Once your transaction is complete, your invoice will be updated automatically. This may take up to 5 minutes.</li>',
    permata_mobile_x: '<li>Open Permata Mobile X and log in</li><li>Select Pay \"Pay Bills\"</li><li>Select “Virtual Account”</li><li>Enter your Virtual Account number (<strong>{va_number}</strong>)</li><li>Your payment details will appear on the screen</li><li>The billed amount will appear on the screen. Choose the payment source</li><li>Confirm your transaction</li><li>Enter your response token code</li><li>Your transaction is complete</li><li>Once your transaction is complete, your invoice will be updated automatically. This may take up to 5 minutes.</li>',
    permata_mobile: '<li>Open Permata Mobile and log in</li><li>Select Pay \"Pay Bills\"</li><li>Select the “Transfer” menu</li><li>Choose your payment source</li><li>Select “New Bill”</li><li>Enter your Virtual Account number (<strong>{va_number}</strong>)</li><li>Review your transaction details</li><li>Confirm your transaction</li><li>Enter the SMS token response</li><li>Your payment is successful</li><li>Once your transaction is complete, your invoice will be updated automatically. This may take up to 5 minutes.</li>',
    permata_internet: '<li>Visit https://new.permatanet.com or <a href=\"https://new.permatanet.com\" target=\"_blank\">click here</a> and log in</li><li>Select the “Payment” menu.</li><li>Choose “Bill Payment”.</li><li>Select “Virtual Account”</li><li>Choose the payment source</li><li>Select the \"Add New Bill\" option</li><li>Enter your Virtual Account number (<strong>{va_number}</strong>)</li><li>Confirm your transaction</li><li>Enter the SMS token response</li><li>Your payment is successful</li><li>Once your transaction is complete, your invoice will be updated automatically. This may take up to 5 minutes</li>',
    sahabat_atm: '<li>Select the \'Other Transactions\' menu</li><li>Choose \'Transfer\'</li><li>Select \'Transfer to Sahabat Sampoerna Bank\'</li><li>Enter your Virtual Account number (<strong>{va_number}</strong>) and press \'Correct\'</li><li>Double-check your transaction details</li><li>Confirm the payment by pressing \'Yes\' if all details are correct</li>',
    sahabat_mobile: '<li>Log in to Mobile Banking</li><li>Select the \'Fund Transfer\' menu</li><li>Select \'Transfer to Other Banks\'</li><li>Enter the Sahabat Sampoerna Bank code \"523\"</li><li>Enter your Virtual Account number (<strong>{va_number}</strong>) and the payment amount</li><li>Enter your Mobile Banking token</li><li>Transaction successful</li>',
    sahabat_internet: '<li>Log in to Internet Banking</li><li>Select the \'Fund Transfer\' menu</li><li>Select \'Transfer to Sahabat Sampoerna Bank Account\'</li><li>Enter your Virtual Account number (<strong>{va_number}</strong>)</li><li>Enter the amount to be paid</li><li>Enter your i-Banking token</li>',
    bca_mobile: '<li>Open the BCA Mobile app</li><li>Select \"m-BCA\", then choose \"m-Transfer\"</li><li>Select \"BCA Virtual Account\"</li><li>Enter the Virtual Account number <strong>{va_number}</strong>, then click \"OK\"</li><li>Click the \"Send\" button at the top right corner of the app to proceed with the transfer</li><li>Click \"OK\" to confirm the payment</li><li>Enter your PIN to authorize the transaction</li><li>Once your payment is complete, this invoice will be automatically updated. It may take up to 5 minutes.</li>',
    bca_atm: '<li>Insert your ATM card and BCA PIN</li><li>From the main menu, select \"Other Transactions\". Choose \"Transfer\", then \"To BCA Virtual Account\"</li><li>Enter the Virtual Account number <strong>{va_number}</strong></li><li>Ensure the Virtual Account details are correct, then enter the amount to be paid, and press \"Confirm\"</li><li>Review and confirm the payment details on the ATM screen. Press \"Yes\" if the details are correct, or \"No\" if there are errors</li><li>Your transaction is now complete. Select \"No\" if you do not wish to continue with another transaction</li><li>Once your payment is complete, this invoice will be automatically updated. It may take up to 5 minutes.</li>',
    bca_internet: '<li>Log in to KlikBCA Individual</li><li>Select \"Transfer\", then choose \"Transfer to BCA Virtual Account\"</li><li>Enter the Virtual Account number <strong>{va_number}</strong></li><li>Click \"Continue\" to proceed with the payment</li><li>Enter the \"RESPON KEYBCA APPLI 1\" generated by your BCA Token, then click \"Submit\"</li><li>Your transaction is complete</li><li>Once your payment is complete, this invoice will be automatically updated. It may take up to 5 minutes.</li>'
  }
}
