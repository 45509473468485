<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" offset-sm="3" md="4" offset-md="4">
        <div class="text-center" style="padding-bottom: 150px;">
          <div class="mt-12 px-2">
            <div class="img-order">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="176" height="176" viewBox="0 0 176 176" class="order_success--img">
                <defs>
                  <linearGradient id="a" x1="0.178" y1="10.24" x2="0.588" y2="8.094" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#5778ff" />
                    <stop offset="0.04" stop-color="#5b7cff" />
                    <stop offset="0.087" stop-color="#6a89ff" />
                    <stop offset="0.137" stop-color="#819fff" />
                    <stop offset="0.19" stop-color="#a2bdff" />
                    <stop offset="0.224" stop-color="#bcd5ff" />
                  </linearGradient>
                  <linearGradient id="b" x1="0.675" y1="10.449" x2="0.397" y2="8.898" gradientUnits="objectBoundingBox">
                    <stop offset="0" stop-color="#c0d6ff" />
                    <stop offset="0.298" stop-color="#c4daff" />
                    <stop offset="0.649" stop-color="#d3e9ff" />
                    <stop offset="1" stop-color="#e9ffff" />
                  </linearGradient>
                  <filter id="c" x="96" y="77" width="58" height="58" filterUnits="userSpaceOnUse">
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="3" result="d" />
                    <feFlood flood-opacity="0.161" />
                    <feComposite operator="in" in2="d" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g transform="translate(-92 -179)">
                  <circle class="a" cx="88" cy="88" r="88" transform="translate(92 179)" />
                  <g transform="translate(-201.099 120.856)">
                    <path class="b" d="M390.765,246.623l-61.033-65.457,34.8-10.813,37.861,44.66L416.5,246.859Z" transform="translate(-22.298 -46.073)" />
                    <g transform="translate(316.611 87.845)">
                      <g transform="translate(50.168 92.812)">
                        <path class="c" d="M528.412,305.162l-1.317,4.9,0,0a.2.2,0,0,1-.161.148l-61.4,16.451a.779.779,0,0,1-.67-.21l-7.348-7.348a.31.31,0,0,1-.109-.236h0v0c0-.005,0-.01,0-.015l1.33-4.945,3.575,3.525,56.652-15.18a.779.779,0,0,1,.67.21l2.522,2.522Z" transform="translate(-457.407 -297.307)" />
                        <path class="d" d="M521.819,291.657l-61.4,16.451c-.2.053-.225.235-.056.4l7.348,7.348a.78.78,0,0,0,.67.21l61.4-16.451c.2-.054.226-.235.056-.4l-7.348-7.348A.778.778,0,0,0,521.819,291.657Z" transform="translate(-458.932 -291.642)" />
                        <path class="e" d="M528.82,299.753l-48.127,12.487a29.539,29.539,0,0,0-.105-5.38l48.158-12.9A29.278,29.278,0,0,1,528.82,299.753Z" transform="translate(-469.813 -292.88)" />
                        <path class="f" d="M528.273,298.8,470.38,314.312c-.137.037-.154.16-.038.277l1.846,1.846a.533.533,0,0,0,.458.143l57.892-15.512c.137-.036.154-.16.038-.276l-1.846-1.846A.533.533,0,0,0,528.273,298.8Z" transform="translate(-464.289 -295.468)" />
                        <path class="g" d="M530.538,301.065l-1.177.316-1-1a.533.533,0,0,0-.458-.143l-56.715,15.2-.849-.849c-.116-.116-.1-.24.038-.277L528.273,298.8a.533.533,0,0,1,.458.143l1.846,1.846C530.693,300.905,530.675,301.029,530.538,301.065Z" transform="translate(-464.289 -295.468)" />
                      </g>
                      <path class="h" d="M350.121,104.869l48.162-12.905h0c-2.006,7.485.81,17.994,6.289,23.473l48.737,48.737c6.44,6.44,9.75,18.793,7.393,27.591h0l-48.162,12.9h0c2.357-8.8-.952-21.151-7.393-27.591l-48.737-48.737c-5.479-5.479-8.295-15.988-6.289-23.472Z" transform="translate(-349.475 -91.964)" />
                      <path class="i" d="M533.319,305.323l-48.162,12.9c.1-.386.192-.781.274-1.181h0l48.158-12.9C533.511,304.543,533.422,304.938,533.319,305.323Z" transform="translate(-422.091 -205.522)" />
                      <g class="j" transform="translate(30.471 37.839)">
                        <path class="k" d="M456.238,233.456l-10.909,2.923.771.771,10.909-2.923Z" transform="translate(-431.246 -205.529)" />
                        <path class="k" d="M476.312,230.231l-3.928,1.052.771.771L477.083,231Z" transform="translate(-445.726 -203.803)" />
                        <path class="k" d="M487.089,227.732l-2.669.715.771.771,2.669-.715Z" transform="translate(-452.167 -202.466)" />
                        <path class="k" d="M447.486,232.531l-5.944,1.593.771.771,5.944-1.593Z" transform="translate(-429.219 -205.034)" />
                        <path class="k" d="M465.2,227.371l-7.284,1.952.771.771,7.284-1.952Z" transform="translate(-437.982 -202.272)" />
                        <path class="k" d="M479.842,224.872l-2.669.715.771.771,2.669-.715Z" transform="translate(-448.289 -200.935)" />
                        <path class="k" d="M488.718,222.493l-2.669.715.771.771,2.669-.715Z" transform="translate(-453.039 -199.662)" />
                        <path class="k" d="M442.055,229.691l-4.3,1.152.771.771,4.3-1.152Z" transform="translate(-427.192 -203.514)" />
                        <path class="k" d="M452.063,227.883l-1.47.394.771.771,1.47-.394Z" transform="translate(-434.063 -202.546)" />
                        <path class="k" d="M460.01,225.383l-2.669.715.771.771,2.669-.715Z" transform="translate(-437.675 -201.209)" />
                        <path class="k" d="M449.841,219.232l-15.874,4.253.771.771L450.612,220Z" transform="translate(-425.165 -197.916)" />
                        <path class="k" d="M475.632,216.007l-3.928,1.052.771.771,3.928-1.052Z" transform="translate(-445.362 -196.19)" />
                        <path class="k" d="M434.481,222.116l-4.3,1.152.771.771,4.3-1.152Z" transform="translate(-423.138 -199.46)" />
                        <path class="k" d="M451.444,216.3l-8.426,2.258.771.771,8.426-2.258Z" transform="translate(-430.009 -196.346)" />
                        <path class="k" d="M467.4,213.8l-2.669.715.771.771,2.669-.715Z" transform="translate(-441.631 -195.008)" />
                        <path class="k" d="M435.192,215.735l-8.8,2.358.771.771,8.8-2.358Z" transform="translate(-421.111 -196.045)" />
                        <path class="k" d="M455.273,211.106l-6.363,1.705.771.771,6.363-1.705Z" transform="translate(-433.162 -193.568)" />
                        <path class="k" d="M470.486,207.666l-4.3,1.152.771.771,4.3-1.152Z" transform="translate(-442.408 -191.727)" />
                        <path class="k" d="M425.577,215.307l-2.972.8.771.771,2.972-.8Z" transform="translate(-419.084 -195.816)" />
                        <path class="k" d="M436.512,212.082l-3.928,1.052.771.771,3.928-1.052Z" transform="translate(-424.425 -194.09)" />
                        <path class="k" d="M450.949,207.473l-6.328,1.7.771.771,6.329-1.7Z" transform="translate(-430.867 -191.623)" />
                        <path class="k" d="M427.922,207.985l-9.1,2.439.771.771,9.1-2.439Z" transform="translate(-417.057 -191.897)" />
                        <path class="k" d="M450.519,202.107l-8.53,2.286.771.771,8.53-2.286Z" transform="translate(-429.459 -188.751)" />
                        <path class="k" d="M466.6,199.608l-2.669.715.771.771,2.669-.715Z" transform="translate(-441.199 -187.414)" />
                        <path class="k" d="M475.547,197.209l-2.669.715.771.771,2.669-.715Z" transform="translate(-445.99 -186.13)" />
                        <path class="k" d="M420.485,206.3l-5.454,1.462.771.771,5.454-1.461Z" transform="translate(-415.031 -190.996)" />
                        <path class="k" d="M516.4,218.367l7.564-2.027.771.771-7.564,2.027Z" transform="translate(-469.285 -196.369)" />
                        <path class="k" d="M516.1,214.145l5.944-1.593.771.771-5.944,1.593Z" transform="translate(-469.123 -194.342)" />
                        <path class="k" d="M515.85,209.918l4.3-1.152.771.771-4.3,1.152Z" transform="translate(-468.988 -192.315)" />
                        <path class="k" d="M507.856,206.654l6.256-1.676.771.771-6.256,1.676Z" transform="translate(-464.71 -190.288)" />
                        <path class="k" d="M508.275,202.343l4.3-1.152.771.771-4.3,1.152Z" transform="translate(-464.934 -188.261)" />
                        <path class="k" d="M502.005,198.865l5.454-1.461.771.771-5.454,1.462Z" transform="translate(-461.579 -186.234)" />
                        <path class="k" d="M503.559,194.412l2.972-.8.771.771-2.972.8Z" transform="translate(-462.41 -184.207)" />
                        <path class="k" d="M493.776,191.372l5.759-1.543.771.771-5.759,1.543Z" transform="translate(-457.174 -182.18)" />
                        <path class="k" d="M490.643,187.5l5.454-1.462.771.771-5.454,1.461Z" transform="translate(-455.498 -180.153)" />
                        <path class="k" d="M432.67,204l-2.319.621.771.771,2.319-.621Z" transform="translate(-423.23 -189.766)" />
                        <path class="k" d="M444.148,200.033l-5.221,1.4.771.771,5.221-1.4Z" transform="translate(-427.819 -187.641)" />
                        <path class="k" d="M433.127,176.852l-8.811,2.361.771.771,8.811-2.361Z" transform="translate(-420 -175.235)" />
                        <path class="k" d="M452.259,173.373l-3.47.93.771.771,3.47-.93Z" transform="translate(-433.098 -173.373)" />
                      </g>
                    </g>
                  </g>
                  <g transform="translate(148 68)">
                    <g class="n" transform="matrix(1, 0, 0, 1, -56, 111)">
                      <rect class="l" width="40" height="40" rx="20" transform="translate(105 83)" />
                    </g>
                    <path class="m" d="M24,6,10.25,21,4,14.182" transform="translate(55 200.5)" />
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div class="mt-10">
            <div class="font-24 font-weight-bold">
              {{ $t('dialog.success_order') }}
            </div>
          </div>
          <div class="mt-2 font-14">
            {{ $t('dialog.your_order_will_processed') }}
          </div>
          <div class="mt-10">
            <div class="font-14">
              {{ $t('dialog.order_no') }}
            </div>
            <div class="mt-2 primary--text font-weight-bold font-24">
              {{ order.order_no }}
            </div>
          </div>
          <div class="mt-10">
            <v-btn block color="accent" height="40" class="border-radius-5 white--text text-none mb-4 letter-space-0" @click="goContactSeller">
              <v-icon left>
                mdi-whatsapp
              </v-icon>
              {{ $t('order.contact_seller') }}
            </v-btn>
            <v-btn block color="white" height="40" class="border-radius-5 text-none font-weight-bold letter-space-0" @click="goListOrder">
              {{ $t('profile.my_order') }}
            </v-btn>
            <v-btn v-if="!order.is_paid && order.payment_mode_id === 10" color="primary" text block height="40" class="text-none font-weight-700 mt-4 letter-space-0" @click="changePayment()">
              {{ $t('v2.use_other_payment_method') }}
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="__menu-bottom menu-full">
      <v-col cols="12" sm="6" offset-sm="3" md="4" offset-md="4" class="pa-0">
        <v-btn color="primary" x-large block class="text-none" @click="goOrderDetail()">
          {{ $t('dialog.detail_order') }}
        </v-btn>
      </v-col>
    </div>
  </v-container>
</template>
<script>
import { orderCalculation } from '@/api/order'
import { getVoucherDetail } from '@/api/voucher'
import { shipperList } from '@/api/shipping'
import { prepareCartItem } from '@/utils/order'
export default {
  data() {
    return {
      order: {},
      temporary_cart: {},
      temporary_shipping: {}
    }
  },
  mounted() {
    if (JSON.parse(localStorage.getItem(process.env.VUE_APP_COOKIE_KEY + '_order'))) {
      this.order = JSON.parse(localStorage.getItem(process.env.VUE_APP_COOKIE_KEY + '_order'))
    } else {
      this.$router.push({ name: 'home' }).catch(() => {})
    }
    this.$store.dispatch('remove_cart')
  },
  methods: {
    goContactSeller() {
      if (!this.$isEmpty(this.$store.state.outlet.selected_outlet)) {
        window.open('https://wa.me/' + this.$store.state.outlet.selected_outlet.setting.whatsapp, '_blank')
      }
    },
    goListOrder() {
      this.$router.push({ name: 'orders' }).catch(() => {})
    },
    goOrderDetail() {
      this.$router.push({ name: 'order', params: { id: this.order.id }}).catch(() => {})
    },
    async checkVoucher(id) {
      try {
        const { data } = await getVoucherDetail(id)
        this.$set(this.temporary_cart, 'discount_voucher_id', id)
        this.$set(this.temporary_cart, 'discount_voucher_detail', data.data)
      } catch (e) {
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: e.message
        })
      }
    },
    async getShipper() {
      try {
        const { data } = await shipperList(this.temporary_cart)
        const shipperSupport = ['Instant', 'Same Day', 'Regular', 'Express', 'Cargo']
        const getData = data.data.filter(shipper => {
          if (shipperSupport.includes(shipper.name)) {
            if (shipper.item.length > 0) {
              return shipper
            }
          }
        })
        const idx_type = getData.findIndex((e) => e.name === this.order.shipping_service_type)
        const idx = getData[idx_type].item.findIndex((e) => e.courier_id === this.order.shipping_courier_id)
        const item = getData[idx_type].item[idx]
        const objShipper = {
          name: item.logistic_name,
          type: item.rate_type,
          shipping_courier_id: item.rate_id,
          shipping_service_type: item.rate_type,
          shipment_method: item.rate_type,
          shipment_working_hour: item.working_hour,
          price: item.final_price,
          logo: item.image_url,
          must_use_insurance: item.must_use_insurance,
          insurance_fee: item.insurance_fee,
          insurance_details: item.insurance_details,
          insurance_included: item.insurance_included
        }
        this.temporary_shipping = objShipper

        this.temporary_cart.shipping_courier_id = item.rate_id
        this.temporary_cart.shipping_service_type = this.order.shipping_service_type
        this.temporary_cart.shipping_insurance = false
      } catch (e) {
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: e.message
        })
      }
    },
    async changePayment(isCart = 0) {
      this.temporary_cart.item = []
      for (const item of this.order.items) {
        this.temporary_cart.item.push(prepareCartItem(item))
      }
      this.temporary_cart.remark = this.order.remark
      this.temporary_cart.currency_id = this.order.currency_id
      this.temporary_cart.notes = ''
      this.temporary_cart.shipping_address = ''
      this.temporary_cart.shipping_address_is_different = 1
      this.temporary_cart.without_shipping = 1
      if (!isCart) {
        this.temporary_cart.previousOrderId = this.order.id
        this.temporary_cart.shipping_address_id = null
        this.temporary_cart.shipping_address = this.order.shipping_address
        this.temporary_cart.shipping_lat = Number(this.order.shipping_lat)
        this.temporary_cart.shipping_lon = Number(this.order.shipping_lon)
        this.temporary_cart.shipping_subdistrict_id = this.order.shipping_subdistrict_id
        this.temporary_cart.shipping_city_id = this.order.shipping_city_id
        this.temporary_cart.shipping_country_id = this.order.shipping_country_id
        this.temporary_cart.shipping_name = 'Kantor'
        this.temporary_cart.shipping_note = this.order.shipping_note
        this.temporary_cart.notes = ''
        this.temporary_cart.without_shipping = this.order.remark === 'DELIVERY' ? 0 : 1
        this.temporary_cart.total = Number(this.order.total_amount)
        this.temporary_cart.shipping_radius = this.order.shipping_radius

        if (this.order.discount_voucher_id) {
          this.checkVoucher(this.order.discount_voucher_id)
        }

        if (this.order.remark === 'DELIVERY') {
          if (Number(this.order.shipping_delivery_type) === 2) {
            await this.getShipper()
          } else {
            this.temporary_shipping.id = 1
            this.temporary_shipping.type = 'free'
            this.temporary_shipping.name = 'Gratis Antar'
            this.temporary_shipping.remark = 'DELIVERY'
            this.temporary_shipping.logo = 'free_delivery'
            this.temporary_shipping.detail = 'Gratis'
            this.temporary_shipping.price = '0'
            this.temporary_shipping.fprice = 'IDR 0'
          }
        } else {
          this.temporary_shipping.id = 3
          this.temporary_shipping.type = 'SelfTake'
          this.temporary_shipping.name = 'Ambil Sendiri'
          this.temporary_shipping.remark = 'SELF PICK-UP'
          this.temporary_shipping.logo = 'pickup'
          this.temporary_shipping.detail = ''
          this.temporary_shipping.price = '0'
          this.temporary_shipping.fprice = 'IDR 0'
        }
        this.$store.commit('SET_TEMPORARY_SHIPPING', this.temporary_shipping)
      }

      try {
        await orderCalculation(this.temporary_cart, {
          globalLoading: false
        })
        this.$store.dispatch('saveDataCart', this.temporary_cart)
        if (isCart) {
          this.$router.push({ name: 'shopping_cart' })
        } else {
          this.$router.push({ name: 'payment_method' })
        }
      } catch (e) {
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: e.message
        })
      }
    }
  }
}
</script>
