export function parseNote(note) {
  const arrNote = note.split(',')
  if (arrNote[0] === 'TAKE AWAY') {
    arrNote.splice(0, 1)
    return [true, arrNote.join(',').trim()]
  } else {
    return [false, arrNote.join(',').trim()]
  }
}

export function prepareCartItem(item) {
  const list_item_combo = item.item_product_combo ? item.item_product_combo.map(item_combo => ({
    id: item_combo.product_combo_item_id,
    variant_id: item_combo.product_variant_id || '',
    name: item_combo.item_name,
    variant_name: item_combo.item_variant_name,
    image: item_combo.image,
    disabled: false,
    qty: item_combo.qty,
    stock_qty: item_combo.stock_qty,
    hold_qty: item_combo.hold_qty,
    is_out_stock: item_combo.is_out_stock,
    track_inventory: item_combo.track_inventory,
    published: item_combo.published,
    weight: item_combo.weight || 0
  })) : []
  const product_note = item.note.split(',').join(',').trim()
  const product_addon = item.addon.map(addon => ({
    product_addon_id: addon.product_addon_id,
    product_addon_name: addon.name
  }))

  return {
    addons: product_addon,
    image: item.product_combo_id ? item.product_combo_photo_md : item.product_photo_md,
    hold_qty: item.hold_qty || 0,
    is_out_stock: item.is_out_stock,
    klasifikasi_id: item.product_group_id || null,
    name: item.item_name,
    non_service_charge: item.non_service_charge || 0,
    non_taxable: item.non_taxable || 0,
    note: product_note,
    product_combo_id: item.product_combo_id || null,
    product_combo_items: list_item_combo,
    product_id: item.product_id || null,
    product_variant_id: item.product_variant_id || null,
    product_variant_name: item.product_combo_id ? null : item.item_variant_name,
    qty: item.qty,
    sell_price: item.price,
    stock_qty: item.stock_qty || 0,
    sub_total: item.amount,
    track_inventory: item.track_inventory || 0,
    weight: item.weight || 0,
    fweight: item.fweight,
    checked: true,
    min_order: item.min_order,
    max_order: item.max_order
  }
}
