<template>
  <div>
    <v-app-bar app flat color="white" class="app-bar-no-padding">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="d-flex align-center">
              <v-btn icon class="mr-2" @click="goBack">
                <v-icon>
                  mdi-arrow-left
                </v-icon>
              </v-btn>
              <div class="font-18 font-weight-bold">
                {{ $t('product.my_cart') }}
              </div>
              <v-spacer />
              <v-menu v-if="!$isEmpty(dataInCart) && dataInCart.item.length > 0" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn height="40" width="40" icon class="soft-black-color-text" v-bind="attrs" v-on="on">
                    <v-icon dark>
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>
                <v-list dense class="py-0">
                  <v-list-item @click="deleteChecked()">
                    <v-list-item-title class="red--text font-14">
                      {{ $t('product.remove_market') }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <div v-if="!$isEmpty(dataInCart) && dataInCart.item.length !== 0" class="detail-store" :class="_handleStoreClose ? 'isClose' : null">
      <v-container :class="{ 'pa-0': $vuetify.breakpoint.width < 600 }">
        <v-row :class="{ 'ma-0': $vuetify.breakpoint.width < 600 }">
          <v-col cols="12" :class="['py-0', { 'pa-0': $vuetify.breakpoint.width < 600 }]">
            <div class="wrap-detail-store">
              <div class="list-store">
                <div class="store-detail-cart">
                  <div class="store-detail-cart-avatar">
                    <img :src="$store.state.outlet.selected_outlet.logo" :alt="$store.state.outlet.selected_outlet.name">
                  </div>
                  <div class="store-detail-cart-content">
                    <div class="store-detail-cart-title">
                      {{ $store.state.outlet.selected_outlet.name }}
                    </div>
                    <div class="font-12">
                      <store-status variant="variant-2" />
                    </div>
                  </div>
                </div>
              </div>
              <v-spacer />
              <div class="detail-address" @click="openDialogAddress()">
                <div class="wrap-detail-address">
                  <div v-if="!dataInCart.shipping_address || dataInCart.shipping_address === ''" class="content-detail-address">
                    <div class="detail-address-title">
                      {{ $t('order.shipping_address') }}
                    </div>
                  </div>
                  <div v-else class="content-detail-address">
                    <div class="detail-address-title">
                      {{ dataInCart.shipping_name }}
                    </div>
                    <div class="detail-address-subtitle">
                      {{ dataInCart.shipping_radius }} km {{ $t('product.from_store') }}
                    </div>
                  </div>
                  <div class="detail-address-icon">
                    <v-img
                      :src="require('@/assets/img/png/Icon-feather-map-pin@2x.png')"
                      height="24"
                      width="24"
                      min-height="24"
                      min-width="24"
                      contain
                    />
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="mt-4">
      <v-container :class="[{ 'pa-4 fill-height': $vuetify.breakpoint.width < 600 }]">
        <v-row :class="{ 'ma-min-4 fill-height': $vuetify.breakpoint.width < 600 }">
          <v-col v-if="!$isEmpty(dataInCart) && dataInCart.item.length !== 0" cols="12" md="8" :class="{ 'pa-4 fill-height': $vuetify.breakpoint.width < 600 }">
            <div class="card--input">
              <div class="card-cart-title">
                <div class="card-cart-title-content">
                  <v-checkbox v-model="checklist_all" hide-details color="#1685C7" class="pa-0 ma-0" @change="checkAll($event)" />
                  <div class="card-cart-text-title">
                    {{ $t('product.product') }}
                  </div>
                </div>
                <v-spacer />
                <div class="card-cart-action">
                  <v-btn icon color="#6EBE46" @click="$router.push({ name: 'home' })">
                    <v-icon>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </div>
              </div>
              <template v-if="dataInCart.item.length !== 0">
                <div v-for="(item, idx) in dataInCart.item" :key="idx">
                  <item-cart :item="item" :index="idx" @click="handleClickItemCart" @plus="plus(idx)" @minus="minus(idx)" @changeQty="changeQty" @clickcheck="changeChecked" />
                </div>
              </template>
            </div>

            <div v-if="!$isEmpty(dataInCart) && dataInCart.item.length !== 0" class="card--input pa-1 mt-4">
              <v-textarea v-model="dataInCart.notes" hide-details flat height="56" solo dense :placeholder="$t('global.note')" prepend-inner-icon="mdi-message-bulleted" @input="updateNotes()" />
            </div>
          </v-col>

          <v-col v-if="!$isEmpty(dataInCart) && dataInCart.item.length !== 0" cols="12" md="4" :class="{ 'pa-4 fill-height': $vuetify.breakpoint.width < 600 }">
            <div class="card--input cursor-pointer" @click="dialog.voucher = true">
              <div v-if="dataInCart.discount_voucher_id === null || $isEmpty(dataInCart.discount_voucher_detail)" class="wrap-col-discount">
                <div class="wrap-col-discount-title">
                  {{ $t('product.use_vaoucher') }}
                </div>
                <v-spacer />
                <div class="wrap-col-discount-budge">
                  {{ totalVoucher }}
                </div>
              </div>
              <div v-else class="wrap-col-discount">
                <div class="wrap-col-discount-title">
                  {{ dataInCart.discount_voucher_detail.title }}
                </div>
                <v-spacer />
                <div class="wrap-col-discount-budge">
                  <v-btn text small icon color="grey" class="white" @click.stop="removeVoucher()">
                    <v-icon>
                      mdi-close-circle
                    </v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
            <div class="title--shipping mt-4">
              {{ $t('product.shipping') }}
            </div>
            <div class="card--input card--input-no-border-bottom cursor-pointer mt-4" @click="openDialogShipping()">
              <div v-if="temporary_shipping && !$isEmpty(temporary_shipping)" class="wrap-col-shipping">
                <div class="wrap-avatar">
                  <img :src="temporary_shipping.type === 'free' || temporary_shipping.type === 'SelfTake' ? require('@/assets/img/png/' + temporary_shipping.logo + '.png') : temporary_shipping.logo" :alt="temporary_shipping.type">
                </div>
                <div class="wrap-col-content">
                  <div class="wrap-col-shipping-title">
                    {{ temporary_shipping.name }}
                  </div>
                  <div class="wrap-col-shipping-subtitle">
                    <span v-if="temporary_shipping.type !== 'free' && temporary_shipping.type !== 'SelfTake'">
                      {{ temporary_shipping.type }}
                    </span>
                  </div>
                </div>
                <v-spacer />
                <div v-if="temporary_shipping.type !== 'SelfTake' && data_calculation.shipping_cost < 1" class="font-14">
                  {{ $t('product.free') }}
                </div>
                <span v-if="temporary_shipping.type !== 'SelfTake' && data_calculation.shipping_cost > 0" v-mask-money="data_calculation.shipping_cost" class="font-14" />
                <v-icon color="#272727">
                  mdi-chevron-right
                </v-icon>
              </div>
              <div v-else class="wrap-col-shipping">
                <div class="wrap-col-shipping-title">
                  {{ $t('product.choose_shipping') }}
                </div>
                <v-spacer />
                <v-icon color="#272727">
                  mdi-chevron-right
                </v-icon>
              </div>
            </div>
            <template v-if="(temporary_shipping && !$isEmpty(temporary_shipping))">
              <div v-if="temporary_shipping.type !== 'free' && temporary_shipping.type !== 'SelfTake'" class="card--input card--input-no-border-top">
                <div class="insurance-wrap">
                  <div class="insurance-left">
                    <div class="insurance-checkbox">
                      <!-- <v-checkbox v-model="insurance" hide-details color="#1685C7" class="pa-0 ma-0 insurance-checkbox-input" :readonly="temporary_shipping.must_use_insurance || temporary_shipping.type === 'Instant' || temporary_shipping.type === 'Same Day'" /> -->
                      <v-checkbox v-model="insurance" hide-details color="#1685C7" class="pa-0 ma-0 insurance-checkbox-input" :readonly="temporary_shipping.must_use_insurance" :disabled="temporary_shipping.insurance_included" />
                    </div>
                    <div class="insurance-desc">
                      <span class="insurance-desc-text"> {{ $t('order.shipping_insurance') }}</span>
                      <div v-if="temporary_shipping.insurance_included" class="insurance-desc-additional">
                        Sudah termasuk dalam biaya pengiriman
                      </div>
                    </div>
                  </div>
                  <div v-if="!temporary_shipping.insurance_included" class="insurance-right">
                    <div class="insurance-price">
                      <span v-mask-money="temporary_shipping.insurance_fee" />
                    </div>
                    <v-icon class="insurance-icon-info" :size="20" @click="handleOpenDetailInsurance">
                      mdi-information-outline
                    </v-icon>
                  </div>
                </div>
              </div>
            </template>
            <div v-if="temporary_shipping.type === 'free' || isFreeShippingNotValid" class="card--input free-shipping-exceed mt-4 d-flex align-center">
              <v-icon size="16" color="#1FAD1F" class="mr-2">
                mdi-information-outline
              </v-icon>
              <div v-if="data_calculation.shipping_cost > 0">
                {{ $t('order.distance_exceeded') }} ({{ $store.state.outlet.selected_outlet.delivery.radius_free_delivery }} km). {{ $t('order.free_shipping_additional_cost') }} {{ $store.state.outlet.selected_outlet.delivery.fcost_outside_radius }}/km
              </div>
              <div v-if="data_calculation.shipping_cost < 1 || isFreeShippingNotValid">
                {{ $t('product.min_total_order') }} {{ $store.state.outlet.selected_outlet.delivery.fminimum_order }}. {{ $t('product.distance_max') }} {{ $store.state.outlet.selected_outlet.delivery.max_radius_delivery }} km
              </div>
            </div>
            <div class="card--list-order mt-4 p-relative">
              <LoadingComponent v-if="isLoadingCalculation" />
              <div class="wrap-col-order">
                <div class="list-item-order">
                  <div class="item-order-title">
                    {{ $t('product.sub_total') }}
                  </div>
                  <v-spacer />
                  <div class="item-order-desc">
                    ({{ totalProductChecked }} {{ $t('product.product') }}) <span v-mask-money-no-currency="data_calculation.subtotal" />
                  </div>
                </div>
                <div v-if="Number(data_calculation.discount_sales) > 0" :class="[`list-item-order`, { 'item-order-discount': Number(data_calculation.discount_sales) > 0 }]">
                  <div class="item-order-title">
                    {{ $t('product.discount') }}
                  </div>
                  <v-spacer />
                  <div class="item-order-desc">
                    <span v-if="Number(data_calculation.discount_sales) > 0">- </span>
                    <span v-mask-money-no-currency="data_calculation.discount_sales" />
                  </div>
                </div>
                <div v-if="Number(data_calculation.discount_voucher_amount) > 0" :class="[`list-item-order`, { 'item-order-discount': Number(data_calculation.discount_voucher_amount) > 0 }]">
                  <div class="item-order-title">
                    Voucher
                    <span v-if="Number(data_calculation.discount_voucher_amount) > 0">
                      ({{ data_calculation.discount_voucher_code }})
                    </span>
                  </div>
                  <v-spacer />
                  <div class="item-order-desc">
                    <span v-if="Number(data_calculation.discount_voucher_amount) > 0">- </span>
                    <span v-mask-money-no-currency="data_calculation.discount_voucher_amount" />
                  </div>
                </div>
                <div v-if="Number(data_calculation.point_earn_expected) > 0" :class="[`list-item-order`, { 'item-order-discount': Number(data_calculation.point_earn_expected) > 0 }]">
                  <div class="item-order-title">
                    Redeem point
                  </div>
                  <v-spacer />
                  <div class="item-order-desc">
                    <span v-if="Number(data_calculation.point_earn_expected) > 0">- </span>
                    <span v-mask-money-no-currency="data_calculation.point_earn_expected" />
                  </div>
                </div>
                <div class="list-item-order">
                  <div class="item-order-title">
                    {{ $t('product.conv_cost') }}
                  </div>
                  <v-spacer />
                  <div class="item-order-desc">
                    <span v-mask-money-no-currency="Number(data_calculation.trans_fee_amount)" />
                  </div>
                </div>
                <div class="list-item-order">
                  <div class="item-order-title">
                    {{ $t('product.service_cost') }}
                  </div>
                  <v-spacer />
                  <div class="item-order-desc">
                    <span v-mask-money-no-currency="Number(data_calculation.service_charge_amount)" />
                  </div>
                </div>
                <div class="list-item-order">
                  <div class="item-order-title">
                    PPN {{ data_calculation.is_tax_include ? `(${$t('order.price_includes_tax')})` : '' }}
                  </div>
                  <v-spacer />
                  <div class="item-order-desc">
                    <span v-mask-money-no-currency="data_calculation.tax" />
                  </div>
                </div>
                <div class="list-item-order">
                  <div class="item-order-title">
                    {{ $t('product.shipping_cost') }} {{ insurance ? `(+${$t('order.insurance')})` : '' }}
                  </div>
                  <v-spacer />
                  <div class="item-order-desc">
                    <span
                      v-if="data_calculation.shipping_cost > 0"
                      v-mask-money-no-currency="data_calculation.shipping_cost"
                    />
                    <span v-else v-html="$t('product.free')" />
                  </div>
                </div>
                <div v-if="data_calculation.rounded_amount > 0 || data_calculation.rounded_amount < 0" class="list-item-order">
                  <div class="item-order-title">
                    {{ $t('order.rounding') }}
                  </div>
                  <v-spacer />
                  <div class="item-order-desc">
                    <span v-mask-money-no-currency="data_calculation.rounded_amount" />
                  </div>
                </div>
                <div class="list-item-order">
                  <div class="item-order-title">
                    {{ $t('product.total_payment') }}
                  </div>
                  <v-spacer />
                  <div class="item-order-desc">
                    <span v-mask-money="data_calculation.total" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="Number(data_calculation.point_earn_expected) > 0" class="mt-4">
              <div class="container-point-earn-expected" v-html="$t('product.potential_to_get_points', { point: Number(data_calculation.point_earn_expected) })" />
            </div>
            <v-btn color="primary" :loading="isLoadingCalculation" :disabled="totalProductChecked < 1" block x-large class="mt-4 text-none" @click="createOrder">
              {{ $t('product.pay') }}
            </v-btn>
          </v-col>

          <v-col v-else cols="12">
            <div class="empty-cart-container">
              <img :src="require('@/assets/img/png/cart.png')" contain>
              <div class="empty-cart-detail">
                {{ $t('product.empty_cart') }}
              </div>
              <v-btn color="primary" block x-large class="btn-empty-cart text-none" @click="goBack">
                {{ $t('product.shop_now') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-dialog v-model="dialog.remove" max-width="310">
      <v-card>
        <v-card-title class="headline">
          {{ $t('dialog.confirm') }}
        </v-card-title>
        <v-card-text>
          {{ $t('dialog.desc_confirm_remove_order') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="grey" text @click="dialog.remove = false">
            {{ $t('dialog.no') }}
          </v-btn>
          <v-btn color="primary" outlined @click="deleteItem(remove_item_idx)">
            {{ $t('dialog.yes') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.address" max-width="350">
      <v-card>
        <div class="dialog-title">
          <div class="dialog-text-title">
            {{ $t('product.choice_address') }}
          </div>
          <v-btn icon height="40" width="40" class="btn-close" @click="dialog.address = false">
            <v-icon color="#272727">
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <v-card-text class="px-4">
          <div>
            <template v-if="list_address.length > 0">
              <div v-for="(item, idx) in list_address" :key="idx" :class="[`list-item-address mb-2`, { 'active': selected_index_address === idx }]" @click="selectAddress(idx)">
                <div class="d-flex align-center item-select-address add-address-select">
                  <div class="select_address" />
                </div>
                <div class="list-item-content">
                  <div class="list-item-content-title">
                    {{ item.name }}
                  </div>
                  <div class="list-item-content-subtitle">
                    {{ item.address }}
                  </div>
                </div>
              </div>
            </template>
            <div :class="[`list-item-address`, { 'active': selected_index_address === 'new' }]" @click="selectAddress('new')">
              <div class="d-flex align-center item-select-address add-address-select">
                <div class="select_address" />
              </div>
              <div class="list-item-content">
                <div class="list-item-content-title">
                  {{ $t('product.new_address') }}
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
        <div class="px-2 pb-2">
          <v-btn color="primary" depressed block class="br-10 text-none" @click="nextAddress()">
            {{ $t('product.choose') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-bottom-sheet v-model="dialog.voucher" :content-class="'bottom-sheet-full sheet-discount'">
      <div class="sheet-discount-inner">
        <div v-touch="{ down: () => swipeDown('Down', 'voucher') }" class="swipe-action swipe-action-sticky">
          <div class="swipe-action_bar" />
        </div>
        <div class="pt-8 px-2 mb-6">
          <v-text-field :placeholder="$t('discount.input_placeholder_voucher_code')" dense hide-details outlined clearable @input="searchVoucher" @click:clear="clearSearchData" />
        </div>
        <div v-scroll.self="onScroll" class="sheet-content py-2 px-2">
          <div v-for="(item, idx) in vouchers" :key="idx" :class="[`item-voucher`, { 'active': selected_voucher_idx === idx }]" @click="selectVoucher(idx, item)">
            <div class="border-half" />
            <div class="border-half right" />
            <div class="d-flex align-center justify-space-between">
              <div class="d-flex align-center">
                <div class="item-voucher-icon">
                  <div class="item-voucher-select">
                    <div />
                  </div>
                </div>
                <div class="item-voucher-content">
                  <div class="item-voucher-title">
                    {{ item.title }}
                  </div>
                  <div v-if="Number(item.discount_amount) !== 0" v-mask-money="item.discount_amount" class="item-voucher-subtitle" />
                  <div v-else class="item-voucher-subtitle">
                    {{ $decimalToPercentage(item.discount_rate, true) }} OFF
                  </div>
                  <div v-if="item.expiry_date" class="item-voucher-desc">
                    {{ $t('discount.valid_until') + ' ' + item.fexpiry_date }}
                  </div>
                  <div v-else-if="item.active_date" class="item-voucher-desc">
                    {{ $t('discount.valid_on') + ' ' + item.factive_date }}
                  </div>
                </div>
              </div>
              <div class="voucher-detail" @click.stop="openVoucherDetail(idx)">
                Detail
              </div>
            </div>
          </div>
        </div>
        <div :class="[`sheet-action`, { 'd-none': selected_voucher_idx === null }]">
          <v-btn color="#F44336" x-large block tile depressed class="text-none white--text font-weight-bold" @click="setVoucher('voucher')">
            {{ $t('product.apply') }}
          </v-btn>
        </div>
      </div>
    </v-bottom-sheet>

    <v-bottom-sheet v-model="dialog.voucher_detail" :content-class="'bottom-sheet-full sheet-discount'">
      <div v-touch="{ down: () => swipeDown('Down', 'voucher_detail') }" class="sheet-discount-inner">
        <div class="voucher-detail-title-wrap">
          <div class="voucher-detail-title">
            {{ $t('discount.voucher_detail') }}
          </div>
          <v-btn text icon class="btnTrackClose" @click="dialog.voucher_detail = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div v-scroll.self="onScroll" class="sheet-content px-4">
          <div class="item-voucher active mb-6">
            <div class="border-half" />
            <div class="border-half right" />
            <div class="d-flex align-center">
              <div class="item-voucher-content">
                <div class="item-voucher-title">
                  {{ voucher_detail.title }}
                </div>
                <div v-if="Number(voucher_detail.discount_amount) !== 0" class="item-voucher-subtitle">
                  <span v-mask-money="voucher_detail.discount_amount" />
                </div>
                <div v-else class="item-voucher-subtitle">
                  {{ $decimalToPercentage(voucher_detail.discount_rate, true) }} OFF
                </div>
              </div>
            </div>
          </div>
          <div class="voucher-content">
            <div class="voucher-content-items">
              <div>
                {{ $t('discount.disc_name') }}
              </div>
              <div>
                {{ voucher_detail.title }}
              </div>
            </div>
            <div class="voucher-content-items greysoft-bg">
              <div>
                {{ $t('discount.amount') }}
              </div>
              <div v-if="Number(voucher_detail.discount_amount) !== 0">
                <span v-mask-money="voucher_detail.discount_amount" />
              </div>
              <div v-else>
                {{ $decimalToPercentage(voucher_detail.discount_rate, true) }} OFF
              </div>
            </div>
            <div class="voucher-content-items">
              <div>
                {{ $t('discount.max_amount') }}
              </div>
              <div>
                {{ voucher_detail.fmax_discount_amount }}
              </div>
            </div>
            <div class="voucher-content-items greysoft-bg">
              <div>
                {{ $t('discount.min_order') }}
              </div>
              <div>
                {{ voucher_detail.fmin_order_amount }}
              </div>
            </div>
            <div class="voucher-content-items">
              <div>
                {{ $t('discount.usage_limit') }}
              </div>
              <div v-if="voucher_detail.usage_limit !== 0">
                {{ voucher_detail.usage_limit }}
              </div>
              <div v-else>
                {{ $t('discount.no_limit') }}
              </div>
            </div>
            <div class="voucher-content-items greysoft-bg">
              <div>
                {{ $t('discount.active_date') }}
              </div>
              <div>
                {{ voucher_detail.factive_date }}
              </div>
            </div>
            <div class="voucher-content-items">
              <div>
                {{ $t('discount.end_date') }}
              </div>
              <div v-if="voucher_detail.fexpiry_date === null">
                {{ $t('discount.no_expiry_date') }}
              </div>
              <div v-else>
                {{ voucher_detail.fexpiry_date }}
              </div>
            </div>
          </div>
          <div class="mb-2">
            {{ $t('discount.valid_for') }}
          </div>
          <ListExpandable
            v-if="voucher_detail?.valid_items?.customers.length < 1"
            :title="$t('profile.customer')"
            :text="$t('discount.valid_for_all_cust')"
            class="mb-2"
            :default-opened="true"
          />
          <ListExpandable
            :title="$t('profile.customer_type')"
            :text="voucher_detail?.valid_items?.customer_types.length > 0 ? stringJoinValidVoucherFor(voucher_detail.valid_items.customer_types) : $t('discount.valid_for_all_cust_type')"
            class="mb-2"
            :default-opened="true"
          />
          <ListExpandable
            :title="$t('product.product_group')"
            :text="voucher_detail?.valid_items?.product_groups.length > 0 ? stringJoinValidVoucherFor(voucher_detail.valid_items.product_groups) : $t('discount.valid_for_all_product_group')"
            class="mb-2"
            :default-opened="true"
          />
          <ListExpandable
            :title="$t('product.product')"
            :text="voucher_detail?.valid_items?.products.length > 0 ? stringJoinValidVoucherFor(voucher_detail.valid_items.products) : $t('discount.valid_for_all_products')"
            class="mb-2"
            :default-opened="true"
          />
          <ListExpandable
            :title="$t('product.brand')"
            :text="voucher_detail?.valid_items?.brands.length > 0 ? stringJoinValidVoucherFor(voucher_detail.valid_items.brands) : $t('discount.valid_for_all_brands')"
            class="mb-2"
            :default-opened="true"
          />
          <ListExpandable
            :title="$t('product.product_combo')"
            :text="voucher_detail?.valid_items?.combos.length > 0 ? stringJoinValidVoucherFor(voucher_detail.valid_items.combos) : $t('discount.valid_for_all_product_combos')"
            class="mb-2"
            :default-opened="true"
          />
        </div>
        <div :class="[`sheet-action`, { 'd-none': selected_voucher_idx === null }]">
          <v-btn color="#F44336" x-large block tile depressed class="text-none white--text font-weight-bold" @click="setVoucher('detail-voucher')">
            {{ $t('product.apply') }}
          </v-btn>
        </div>
      </div>
    </v-bottom-sheet>

    <v-bottom-sheet v-model="dialog.shipping" :content-class="'bottom-sheet-full sheet-shipping'">
      <div v-touch="{ down: () => swipeDown('Down', 'shipping') }" class="swipe-action swipe-action-sticky">
        <div class="swipe-action_bar" />
      </div>
      <v-sheet style="height: 100%">
        <div class="pt-8 px-4 p-relative fill-height">
          <div class="font-18 font-weight-bold">
            {{ $t('product.merchant_shipping') }}
          </div>
          <v-list>
            <template v-if="$store.state.outlet.selected_outlet.delivery.turn_off_private_courier === 0">
              <v-list-item v-for="(item, idx) in dataShippingMerchantFreeDelivery" :key="idx" :disabled="isLoadingCheckShipper" @click="checkShipping(item)">
                <v-list-item-avatar tile>
                  <v-img
                    :src="require('@/assets/img/png/' + item.logo + '.png')"
                    height="32"
                    width="32"
                    min-height="24"
                    min-width="24"
                    contain
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-14 font-weight-bold">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="item.id === 1 && !$isEmpty($store.state.outlet.selected_outlet)" class="font-12">
                    Min. {{ $store.state.outlet.selected_outlet.delivery.fminimum_order }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-else>
              <v-list-item v-for="(item, idx) in dataShippingMerchantNonFreeDelivery" :key="idx" :disabled="isLoadingCheckShipper" @click="checkShipping(item)">
                <v-list-item-avatar tile>
                  <v-img
                    :src="require('@/assets/img/png/' + item.logo + '.png')"
                    height="32"
                    width="32"
                    min-height="24"
                    min-width="24"
                    contain
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-14 font-weight-bold">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="item.id === 1 && !$isEmpty($store.state.outlet.selected_outlet)" class="font-12">
                    Min. {{ $store.state.outlet.selected_outlet.delivery.fminimum_order }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
          <div v-if="checkListShipper" class="font-18 font-weight-bold">
            {{ $t('product.other_shipping') }}
          </div>
          <v-list>
            <v-list-item v-for="(item, idx) in list_shipper" :key="idx" @click="showShippingDetail(item)">
              <v-list-item-content>
                <v-list-item-title class="font-14 font-weight-bold">
                  <span>{{ item.name }}</span>
                </v-list-item-title>
                <v-list-item-subtitle class="font-12" /> <!-- Estimasi kapan sampai -->
              </v-list-item-content>
              <v-list-item-action>
                <div class="d-flex align-center">
                  <div class="estimate-text mr-1" /> <!-- Perkiraan Harga -->
                  <v-icon size="24">
                    mdi-chevron-right
                  </v-icon>
                </div>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>
      </v-sheet>
    </v-bottom-sheet>
    <v-dialog v-model="dialog.subshipping" persistent max-width="400">
      <v-card>
        <v-btn text icon class="btnTrackClose" @click="dialog.subshipping = false">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
        <div class="">
          <div class="font-20 font-weight-bold px-4 py-3">
            {{ subshipping.name }}
          </div>
          <div class="pb-4">
            <div v-if="subshipping.name === 'Instant' || subshipping.name === 'Same Day'" class="px-4 mt-4">
              <div class="d-flex bg-alert-warning-soft px-4 py-4 rounded-lg">
                <div class="mr-4 d-flex align-center">
                  <img src="@/assets/img/png/icon_warning.png" alt="Warning" class="image-24">
                </div>
                <div class="font-14 line-height-normal">
                  {{ $t('order.alert_shipper_info_delivery_time') }}
                </div>
              </div>
            </div>
            <v-list>
              <v-list-item
                v-for="(item, idx) in subshipping.item"
                :key="idx"
                class="list-item-courier"
                :disabled="courier_times_on_close(item).type === 'close' || isLoadingCheckShipper"
                @click="checkShipper(item)"
              >
                <v-list-item-avatar rounded size="32">
                  <v-img class="list-item-courier_img" :src="item.image_url" contain />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-14 font-weight-bold remove-text-ellipsis">
                    {{ item.logistic_name }} - {{ item.rate_name }} {{ item.max_day > 0 ? `(${item.max_day} ${$t('home.days').toLowerCase()})` : '' }}
                  </v-list-item-title>
                  <template v-if="item.rate_type === 'Same Day' || item.rate_type === 'Instant'">
                    <v-list-item-subtitle class="list-item-courier_desc font-12 remove-text-ellipsis" :class="{'closing': courier_times_on_close(item).type === 'closing'}">
                      {{ courier_times_on_close(item).description }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="list-item-courier_desc font-12 remove-text-ellipsis" :class="{'closing': courier_times_on_close(item).type === 'closing'}">
                      {{ `~ ${$t('order.arrived')} ${item.estimate_time}` }}
                    </v-list-item-subtitle>
                  </template>
                </v-list-item-content>
                <v-list-item-action>
                  <div class="d-flex align-center">
                    <div class="estimate-text mr-1">
                      <span v-if="courier_times_on_close(item).type === 'close'">{{ $t('order.not_available') }}</span>
                      <span v-else v-mask-money-no-currency="item.final_price" />
                    </div>
                    <v-btn icon @click.stop="handleOpenTncShipper(item)">
                      <v-icon>
                        mdi-information-outline
                      </v-icon>
                    </v-btn>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.tncshipper" max-width="360" @close="handleCloseTncShipper">
      <v-card>
        <v-list-item
          class="list-item-courier"
        >
          <v-list-item-avatar rounded size="38">
            <v-img class="list-item-courier_img" :src="dataShipperTnC.subShipping.image_url" contain />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="font-weight-bold">
              {{ dataShipperTnC.subShipping.logistic_name }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <div class="px-4 pt-2 font-14">
          <template v-if="['Regular','Trucking','Economy','Cargo','Express'].includes(dataShipperTnC.subShipping.rate_type)">
            <div class="d-flex flex-column" style="gap: 8px">
              <div>
                {{ $t('order.pick_up_operational_days_are') }}
                <span class="font-weight-bold">
                  {{ dataShipperTnC.terms.data.operational_day }}.
                </span>
              </div>
              <div>{{ $t('order.terms_of_pick_up_request_time') }} :</div>
              <div v-for="(operational, index) in dataShipperTnC.terms.data.time_condition" :key="index">
                <div class="font-weight-bold font-18">
                  {{ operational.region }}
                </div>
                <div class="d-flex justify-space-between py-2">
                  <div>{{ $t('order.same_day') }}</div>
                  <div class="font-weight-bold">
                    {{ operational.sameday }}
                  </div>
                </div>
                <div class="d-flex justify-space-between py-2">
                  <div>{{ $t('order.next_day') }}</div>
                  <div class="font-weight-bold">
                    {{ operational.nextday }}
                  </div>
                </div>
              </div>
              <v-alert
                dense
                text
                class="font-14 rounded-lg blue--text"
              >
                <div class="d-flex align-start">
                  <v-icon color="#0078CE" size="20">
                    mdi-information-outline
                  </v-icon>
                  <div class="ml-2">
                    <span class="black--text font-weight-bold font-16">
                      {{ $t('order.information') }}
                    </span>
                    <ul class="black--text">
                      <li v-for="(info, index) in dataShipperTnC.terms.data.info" :key="index">
                        {{ info }}
                      </li>
                    </ul>
                  </div>
                </div>
              </v-alert>
              <v-alert
                dense
                text
                class="font-14 rounded-lg blue--text"
              >
                <div class="d-flex align-start">
                  <v-icon color="#0078CE" size="20">
                    mdi-information-outline
                  </v-icon>
                  <div class="ml-2">
                    <span class="black--text font-weight-bold font-16">{{ $t('order.failed_pick_up_actions') }}</span>
                    <ul class="black--text">
                      <li v-for="(info, index) in dataShipperTnC.terms.data.failed_pick_up" :key="index">
                        {{ info }}
                      </li>
                    </ul>
                  </div>
                </div>
              </v-alert>
            </div>
          </template>
          <template v-if="['Instant', 'Same Day'].includes(dataShipperTnC.subShipping.rate_type)">
            <div>
              <div v-for="(info, index) in dataShipperTnC.terms.data" :key="index" class="d-flex align-center justify-space-between py-2" style="gap: 4px">
                <div style="flex: 1">
                  {{ info.key }}
                </div>
                <div style="flex: 1" class="font-weight-bold">
                  {{ info.value }}
                </div>
              </div>
            </div>
          </template>
        </div>
        <div class="px-4 py-4">
          <v-btn
            elevation="1"
            block
            color="success"
            class="font-16 font-weight-bold text-none"
            @click="handleCloseTncShipper"
          >
            {{ $t('global.ok') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <!-- Detail Item Cart -->
    <v-dialog v-model="openDetailItemCart" max-width="500" @close="handleCloseDetailItemCart">
      <v-card>
        <v-btn icon class="btn-close-dialog" @click="handleCloseDetailItemCart">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
        <DetailItemCart
          ref="refDetailItemCart"
          :product="dataDetailItemCart"
          :selected-product="selectedItemCart"
          :index="dataDetailItemCart_index"
          :product-type="dataDetailItemCart_type"
          :on-alert-delete="handleOpenDialogDeleteProductOnDetailItem"
          :on-delete="handleDeleteProductOnDetailItem"
          :on-save="handleSaveChangeDetailItem"
        />
      </v-card>
    </v-dialog>

    <DialogAlertStoreOff :is-open="dialog.isStoreOff" :on-close="handleCloseDialogStoreOff" :on-submit="createOrderFromDialogStoreOff" />

    <v-dialog v-model="dialog.detailInsurance" max-width="440" @close="handleCloseDetailInsurance">
      <v-card class="px-4 py-4">
        <div class="d-flex justify-center">
          <v-icon :size="52" color="#1FAD1F">
            mdi-shield-check-outline
          </v-icon>
        </div>
        <div class="py-2 mt-2">
          <div v-for="(item, index) in temporary_shipping.insurance_details" :key="index" :class="[{ 'mt-3': index > 0 }]">
            <div class="font-weight-bold">
              {{ item.field }}
            </div>
            <div class="font-14">
              <template v-if="Array.isArray(item.values)">
                <ul>
                  <li v-for="(value, i) in item.values" :key="i">
                    {{ value }}
                  </li>
                </ul>
              </template>
              <template v-else>
                <span>{{ item.values }}</span>
              </template>
            </div>
          </div>
        </div>
        <v-btn
          elevation="1"
          block
          class="mt-4 white font-16 font-weight-bold text-none"
          @click="handleCloseDetailInsurance"
        >
          Oke
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { debounce } from 'debounce'
import {
  distanceMatrix as distanceMatrixMaps
} from '@/api/maps'
import ItemCart from '@/components/item-cart'
import DialogAlertStoreOff from '@/components/outlet/DialogAlertStoreOff.vue'
import { orderCalculation } from '@/api/order'
import { listVoucher } from '@/api/voucher'
import { handleStoreClose, FSTORE_STATUS } from '@/utils/storeOperational'
import { shipperList, shipperTnC } from '@/api/shipping'
import { listAddress } from '@/api/customer'
import LoadingComponent from '@/components/LoadingComponent'
import DetailItemCart from '@/components/DetailItemCart.vue'
import { getProductDetail, getProductComboDetail } from '@/api/product'
import ListExpandable from '@/components/ListExpandable'
import StoreStatus from '@/components/StoreStatus'

export default {
  components: {
    ItemCart,
    LoadingComponent,
    DetailItemCart,
    DialogAlertStoreOff,
    ListExpandable,
    StoreStatus
  },
  data() {
    return {
      dialog: {
        remove: false,
        address: false,
        voucher: false,
        shipping: false,
        subshipping: false,
        voucher_detail: false,
        isStoreOff: false,
        detailInsurance: false,
        tncshipper: false
      },
      params: {
        page: 1,
        per_page: 10
      },
      settingScroll: false,
      remove_item_idx: 0,
      unavailable_product: '',
      dataInCart: {
        remark: 'DELIVERY',
        item: [],
        shipping_address_id: 0,
        shipping_address: '',
        shipping_name: '',
        shipping_note: '',
        shipping_address_is_different: 1,
        notes: '',
        currency_id: 'IDR',
        without_shipping: 1
      },
      list_address: [],
      vouchers: [],
      totalVoucher: 0,
      selected_voucher_idx: null,
      selected_voucher: {},
      checklist_all: true,
      addressFrom: 'btnAddress',
      selected_index_address: null,
      selected_address: {},
      dataShippingMerchantFreeDelivery: [
        { id: 1, type: 'free', name: 'Gratis Antar', remark: 'DELIVERY', logo: 'free_delivery', detail: 'Gratis', price: '0', fprice: 'IDR 0' },
        { id: 3, type: 'SelfTake', name: 'Ambil Sendiri', remark: 'SELF PICK-UP', logo: 'pickup', detail: '', price: '0', fprice: 'IDR 0' }
      ],
      dataShippingMerchantNonFreeDelivery: [
        { id: 3, type: 'SelfTake', name: 'Ambil Sendiri', remark: 'SELF PICK-UP', logo: 'pickup', detail: '', price: '0', fprice: 'IDR 0' }
      ],
      list_shipping: [],
      subshipping: {},
      data_calculation: {},
      voucher_detail: {},
      isLoadingCalculation: false,
      openDetailItemCart: false,
      isLoadingDetailItemCart: false,
      selectedItemCart: null,
      dataDetailItemCart: null,
      dataDetailItemCart_type: 'p',
      dataDetailItemCart_index: null,
      list_shipper: [],
      isLoadingCheckShipper: false,
      isFreeShippingNotValid: false,
      insurance: false,
      dataShipperTnC: {
        subShipping: {},
        terms: {}
      }
    }
  },
  computed: {
    selectedProduct() {
      const selected = []
      this.dataCart.map((item) => {
        if (item.checked) {
          selected.push(item)
        }
      })
      return selected.reduce((acc, item) => acc + item.quantity, 0)
    },
    validationFastDelete() {
      if (this.selectedItem.length === 0) {
        return true
      } else {
        return false
      }
    },
    _fstoreStatus() {
      return FSTORE_STATUS
    },
    selectedOutlet() {
      return this.$store.getters.selectedOutlet
    },
    _handleStoreClose() {
      return handleStoreClose(this.selectedOutlet.fstore_status)
    },
    paramsVoucher() {
      const params = {
        page: 1,
        per_page: 1000
      }
      params.search_column = []
      params.search_operator = []
      params.search_text = []
      if (this.voucher) {
        params.search_column.push('code')
        params.search_operator.push('=')
        params.search_text.push(this.voucher)
      }
      return params
    },
    totalProductChecked() {
      // Untuk menghitung berapa produk yang diceklis
      let totalItemChecked = 0
      this.dataInCart.item.forEach(item => {
        if (item.checked) {
          totalItemChecked += 1
        }
      })
      return totalItemChecked
    },
    checkListShipper() {
      return this.list_shipper.length > 0
    },
    temporary_shipping: {
      get() {
        return this.$store.state.global.temporary_shipping
      },
      set(shipper) {
        this.$store.commit('SET_TEMPORARY_SHIPPING', shipper)
      }
    }
  },
  watch: {
    'dataInCart': {
      handler(newItem) {
        if (newItem.item.length > 0) {
          for (let i = 0; i < newItem.item.length; i++) {
            if (!newItem.item[i].checked) {
              this.checklist_all = false
              break
            } else {
              this.checklist_all = true
            }
          }
        } else {
          this.deleteShipping()
          this.checklist_all = false
        }
      },
      deep: true
    },
    insurance: {
      handler() {
        this.handleUseInsurance()
      }
    }
  },
  async mounted() {
    await this.getData()
    await this.getAddress()
    await this.getVoucher()
    await this.calculation()
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'home' }).catch(() => {})
    },
    getData() {
      const LOCAL_DATA_CART = localStorage.getItem(process.env.VUE_APP_COOKIE_KEY + '_data_cart')
      if (LOCAL_DATA_CART) {
        const parseLocalDataCart = JSON.parse(LOCAL_DATA_CART)
        if (typeof parseLocalDataCart.shipping_address === 'undefined') {
          parseLocalDataCart.shipping_address = ''
        }
        if (typeof parseLocalDataCart.shipping_address_is_different === 'undefined') {
          parseLocalDataCart.shipping_address_is_different = 1
        }
        if (typeof parseLocalDataCart.remark === 'undefined') {
          parseLocalDataCart.remark = 'DELIVERY'
        }
        if (typeof parseLocalDataCart.without_shipping === 'undefined') {
          parseLocalDataCart.without_shipping = 1
        }
        if (typeof parseLocalDataCart.shipping_insurance !== 'undefined') {
          this.insurance = parseLocalDataCart.shipping_insurance
        }
        this.dataInCart = parseLocalDataCart
      }
    },
    handleCloseDialogStoreOff() {
      this.dialog.isStoreOff = false
    },
    async getAddress() {
      try {
        const { data } = await listAddress()
        this.list_address = data.data
        const idx_default = this.list_address.findIndex((e) => { return e.is_default === 1 })
        if (idx_default !== -1) {
          this.selected_index_address = idx_default
          this.selected_address = this.list_address[idx_default]
          if (this.dataInCart.shipping_address === '') {
            this.$set(this.dataInCart, 'shipping_address', this.list_address[idx_default].address)
            this.$set(this.dataInCart, 'shipping_address_id', this.list_address[idx_default].id)
            this.$set(this.dataInCart, 'shipping_name', this.list_address[idx_default].name)
            this.$set(this.dataInCart, 'shipping_lat', Number(this.list_address[idx_default].lat))
            this.$set(this.dataInCart, 'shipping_lon', Number(this.list_address[idx_default].lon))
            this.$set(this.dataInCart, 'shipping_city_id', this.list_address[idx_default].city_id)
            this.$set(this.dataInCart, 'shipping_subdistrict_id', this.list_address[idx_default].subdistrict_id)
            this.$set(this.dataInCart, 'shipping_country_id', this.list_address[idx_default].country_id)
            this.$set(this.dataInCart, 'shipping_note', this.list_address[idx_default].note)
            const destinations = []
            destinations.push({
              lat: Number(this.list_address[idx_default].lat),
              lon: Number(this.list_address[idx_default].lon)
            })
            const form = await {
              origin_lat: Number(this.$store.state.outlet.selected_outlet.location_lat),
              origin_lon: Number(this.$store.state.outlet.selected_outlet.location_lon),
              destination: destinations
            }
            await this.checkDisctance(form)
          }
        } else {
          this.selected_index_address = 0
        }
      } catch (e) {
        this.list_address = []
        this.selected_index_address = 'new'
        if (e.statusCode !== 404) {
          this.$store.dispatch('showAlert', {
            type: 'error',
            show: true,
            message: e.message
          })
        }
      }
    },
    async nextAddress() {
      this.dialog.address = false
      if (this.selected_index_address === 'new') {
        this.$router.push({ name: 'add_address', query: { redirect: 'shopping_cart' }})
      } else {
        this.dataInCart.shipping_address = this.list_address[this.selected_index_address].address
        this.dataInCart.shipping_address_id = this.list_address[this.selected_index_address].id
        this.dataInCart.shipping_name = this.list_address[this.selected_index_address].name
        this.dataInCart.shipping_lat = Number(this.list_address[this.selected_index_address].lat)
        this.dataInCart.shipping_lon = Number(this.list_address[this.selected_index_address].lon)
        this.dataInCart.shipping_city_id = this.list_address[this.selected_index_address].city_id
        this.dataInCart.shipping_subdistrict_id = this.list_address[this.selected_index_address].subdistrict_id
        this.dataInCart.shipping_country_id = this.list_address[this.selected_index_address].country_id
        this.dataInCart.shipping_note = this.list_address[this.selected_index_address].note
        const destinations = []
        destinations.push({
          lat: Number(this.list_address[this.selected_index_address].lat),
          lon: Number(this.list_address[this.selected_index_address].lon)
        })
        const form = await {
          origin_lat: Number(this.$store.state.outlet.selected_outlet.location_lat),
          origin_lon: Number(this.$store.state.outlet.selected_outlet.location_lon),
          destination: destinations
        }
        if (!this.$isEmpty(this.temporary_shipping)) {
          this.deleteShipping()
        }

        if (this.addressFrom === 'btnShipping') {
          this.dialog.shipping = true
          await this.getShipper()
        }

        // Tidak relate dengan kondisi sekarang
        // if (typeof this.dataInCart.shipping_detail !== 'undefined' && !this.$isEmpty(this.dataInCart.shipping_detail)) {
        //   await this.getShipper()
        //   for (let i = 0; i < this.list_shipping.length; i++) {
        //     if (this.list_shipping[i].detail.length > 0) {
        //       for (let x = 0; x < this.list_shipping[i].detail.length; x++) {
        //         if (this.list_shipping[i].detail[x].shipping_courier_id === this.dataInCart.shipping_detail.shipping_courier_id && this.list_shipping[i].detail[x].shipping_service_type === this.dataInCart.shipping_detail.shipping_service_type) {
        //           this.dataInCart.shipping_detail = this.list_shipping[i].detail[x]
        //           await this.calculation()
        //           break
        //         }
        //       }
        //     }
        //   }
        // }
        await this.checkDisctance(form)
        await this.calculation()
      }
    },
    async checkDisctance(data_form) {
      try {
        const { data } = await distanceMatrixMaps(data_form)
        if (data.rows[0].elements[0].distance.value > 1000) {
          const value = Math.round(data.rows[0].elements[0].distance.value / 1000)
          this.$set(this.dataInCart, 'shipping_radius', Number(value.toFixed(0)))
        } else {
          const value = Math.ceil(data.rows[0].elements[0].distance.value / 1000)
          this.$set(this.dataInCart, 'shipping_radius', Number(value.toFixed(0)))
        }
        this.$store.dispatch('saveDataCart', this.dataInCart)
      } catch (e) {
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: e.message
        })
      }
    },
    showShippingDetail(obj) {
      this.subshipping = obj
      this.dialog.subshipping = true
    },
    selectAddress(idx) {
      this.selected_index_address = idx
    },
    checkIncProductIsAvailable(idx, isTextField = false) {
      let product_available = true
      let isErrorProductAvailable = true // Penambahan apakah dia error karena error product avail atau error dari min max
      let unavailable_product = ''
      if (this.dataInCart.item[idx].product_combo_id) {
        // Penambahan flow pengecekan max order
        if (Number(this.dataInCart.item[idx].max_order) > 0) {
          const stock_incart = this.$stockCartCombo(this.dataInCart.item[idx].product_combo_id)
          let conditionStock = stock_incart >= Number(this.dataInCart.item[idx].max_order)
          if (isTextField) {
            conditionStock = stock_incart > Number(this.dataInCart.item[idx].max_order)
          }
          if (conditionStock) {
            product_available = false
            isErrorProductAvailable = false
            unavailable_product = this.dataInCart.item[idx].name
          }
        }
        for (var i = 0; i < this.dataInCart.item[idx].product_combo_items.length; i++) {
          if (this.dataInCart.item[idx].product_combo_items[i].track_inventory) {
            if (this.dataInCart.item[idx].product_combo_items[i].variant_id) {
              const stock_incart = this.$stockCart(this.dataInCart.item[idx].product_combo_items[i].variant_id, 'variant')
              if (this.dataInCart.item[idx].product_combo_items[i].stock_qty - this.dataInCart.item[idx].product_combo_items[i].hold_qty < this.dataInCart.item[idx].product_combo_items[i].qty + stock_incart) {
                product_available = false
                unavailable_product = this.dataInCart.item[idx].product_combo_items[i].name
                break
              }
            } else {
              const stock_incart = this.$stockCart(this.dataInCart.item[idx].product_combo_items[i].id)
              if (this.dataInCart.item[idx].product_combo_items[i].stock_qty - this.dataInCart.item[idx].product_combo_items[i].hold_qty < this.dataInCart.item[idx].product_combo_items[i].qty + stock_incart) {
                product_available = false
                unavailable_product = this.dataInCart.item[idx].product_combo_items[i].name
                break
              }
            }
          }
        }
      } else {
        // Penambahan pengecekan min max untuk product variant dan product biasa
        if (Number(this.dataInCart.item[idx].max_order) > 0) {
          let stock_in_cart = 0
          if (this.dataInCart.item[idx].product_variant_id && this.dataInCart.item[idx].product_id) {
            const stock_incart = this.$stockCart(this.dataInCart.item[idx].product_variant_id, 'variant')
            stock_in_cart = stock_in_cart + stock_incart
          } else {
            const stock_incart = this.$stockCart(this.dataInCart.item[idx].product_id)
            stock_in_cart = stock_in_cart + stock_incart
          }
          let conditionStock = stock_in_cart >= Number(this.dataInCart.item[idx].max_order)
          if (isTextField) {
            conditionStock = stock_in_cart > Number(this.dataInCart.item[idx].max_order)
          }
          if (conditionStock) {
            product_available = false
            isErrorProductAvailable = false
            unavailable_product = this.dataInCart.item[idx].name
          }
        }
        if (this.dataInCart.item[idx].track_inventory) {
          if (this.dataInCart.item[idx].product_variant_id) {
            const stock_incart = this.$stockCart(this.dataInCart.item[idx].product_variant_id, 'variant')
            if (Number(this.dataInCart.item[idx].stock_qty) - Number(this.dataInCart.item[idx].hold_qty) < 1 + stock_incart) {
              product_available = false
              unavailable_product = this.dataInCart.item[idx].name
            }
          } else {
            const stock_incart = this.$stockCart(this.dataInCart.item[idx].product_id)
            if (Number(this.dataInCart.item[idx].stock_qty) - Number(this.dataInCart.item[idx].hold_qty) < 1 + stock_incart) {
              product_available = false
              unavailable_product = this.dataInCart.item[idx].name
            }
          }
        }
      }
      return { product_available, isErrorProductAvailable, unavailable_product }
    },
    async plus(idx, isTextField = false, valueTextField = '', oldValueTextField = '') {
      this.getData()
      const { product_available, isErrorProductAvailable, unavailable_product } = this.checkIncProductIsAvailable(idx, isTextField)

      if (product_available) {
        if (isTextField) {
          this.dataInCart.item[idx].qty = valueTextField
        } else {
          this.dataInCart.item[idx].qty = Number(this.dataInCart.item[idx].qty) + 1
        }
        if (this.dataInCart.item[idx].checked) {
          this.deleteShipping()
          await this.calculation()
        } else {
          this.updateDataCart()
        }
      } else {
        if (isTextField) {
          this.dataInCart.item[idx].qty = oldValueTextField
          this.updateDataCart()
        }
        // Kondisi apakah error dari min max atau error product avail
        const message = isErrorProductAvailable ? this.$t('product.stock_is_not_enough', { data: unavailable_product }) : this.$t('product.invalid_purchase_qty_max')
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: message
        })
      }
    },
    async minus(idx, isTextField = false, valueTextField = '') {
      this.getData()
      let minOrder = 1
      // Pengecekan apakah min ordernya di set dari BO maka akan menggunakan min_order dari BO
      if (this.dataInCart.item[idx].min_order > 0) {
        minOrder = this.dataInCart.item[idx].min_order
      }
      if ((this.dataInCart.item[idx].qty - 1) < minOrder) {
        this.dialog.remove = !this.dialog.remove
        this.remove_item_idx = idx
      } else {
        if (isTextField) {
          this.dataInCart.item[idx].qty = valueTextField
        } else {
          this.dataInCart.item[idx].qty = this.dataInCart.item[idx].qty - 1
        }
        if (this.dataInCart.item[idx].checked) {
          this.$store.commit('REMOVE_TEMPORARY_SHIPPING')
          await this.calculation()
        } else {
          this.updateDataCart()
        }
      }
    },
    changeQty: debounce(async function(callback) {
      this.getData()
      // MINUS
      if (callback.value < this.dataInCart.item[callback.idx].qty) {
        this.minus(callback.idx, true, callback.value)
        return
      }

      // PLUS
      const oldValue = JSON.stringify(this.dataInCart.item[callback.idx].qty)
      this.dataInCart.item[callback.idx].qty = callback.value
      this.updateDataCart()
      this.plus(callback.idx, true, callback.value, Number(JSON.parse(oldValue)))
    }, 500),
    async deleteItem(idx) {
      try {
        this.dialog.remove = !this.dialog.remove
        await this.handleProcessDeleteItem(idx)
        if (this.openDetailItemCart) {
          this.handleCloseDetailItemCart() // tambahan jika dialog detail item aktif
        }
      } catch (error) {
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: this.$t('dialog.failed_to_delete')
        })
      }
    },
    async handleProcessDeleteItem(idx) {
      try {
        this.dataInCart.item.splice(idx, 1)
        await this.calculation()
        this.updateDataCart()
        return Promise.resolve()
      } catch (error) {
        return Promise.reject(new Error(error))
      }
    },
    updateNotes: debounce(async function() {
      this.updateDataCart()
    }, 1000),
    async checkAll(e) {
      if (this.dataInCart.item.length > 0) {
        this.$store.commit('REMOVE_TEMPORARY_SHIPPING')
        for (let i = 0; i < this.dataInCart.item.length; i++) {
          this.$set(this.dataInCart.item[i], 'checked', e)
        }
        await this.calculation()
      }
    },
    async changeChecked(e, idx) {
      this.$store.commit('REMOVE_TEMPORARY_SHIPPING')
      this.$set(this.dataInCart.item[idx], 'checked', e)
      await this.calculation()
    },
    updateDataCart() {
      this.$store.dispatch('saveDataCart', this.dataInCart)
    },
    processCreateOrder() {
      if (this.dataInCart.shipping_address === '') {
        this.openDialogAddress()
      } else if (this.$isEmpty(this.temporary_shipping)) {
        this.dialog.shipping = true
        this.getShipper()
      } else {
        this.$router.push({ name: 'payment_method' })
      }
    },
    createOrder() {
      if (this.selectedOutlet.fstore_status === this._fstoreStatus._OFF) {
        this.dialog.isStoreOff = true
        return
      }
      this.processCreateOrder()
    },
    createOrderFromDialogStoreOff() {
      this.dialog.isStoreOff = false
      this.processCreateOrder()
    },
    openDialogAddress(from = 'btnAddress') {
      this.dialog.address = true
      if (this.list_address.length > 0) {
        if (this.selected_index_address === 'new') {
          this.selected_index_address = 0
        }
      } else {
        this.selected_index_address = 'new'
      }
      this.addressFrom = from
    },
    async calculation() {
      const data_cart = JSON.parse(JSON.stringify(this.dataInCart))
      if (!this.$isEmpty(this.temporary_shipping)) {
        this.isFreeShippingNotValid = false
        if (this.temporary_shipping.type === 'SelfTake') {
          data_cart.without_shipping = 1
          data_cart.remark = 'SELF PICK-UP'
        } else {
          data_cart.without_shipping = 0
          data_cart.remark = 'DELIVERY'
        }
        if (this.temporary_shipping.type !== 'SelfTake' && this.temporary_shipping.type !== 'free') {
          data_cart.shipping_courier_id = this.temporary_shipping.shipping_courier_id
          data_cart.shipping_service_type = this.temporary_shipping.shipping_service_type
          data_cart.shipping_insurance = this.insurance
        } else {
          delete data_cart.shipping_courier_id
          delete data_cart.shipping_service_type
          delete data_cart.shipping_insurance
        }
      }
      const temporary_cart = JSON.parse(JSON.stringify(data_cart))
      temporary_cart.item = temporary_cart.item.filter(e => e.checked === true)

      /**
       * Shipping radius nya null khusus untuk Shipper
       * tidak merubah langsung di data_cart karena takut mengganggu ui radius pada address
       * hanya merubah di temporary_cart nya saja
       */
      if (!this.$isEmpty(this.temporary_shipping)) {
        if (this.temporary_shipping.type !== 'SelfTake' && this.temporary_shipping.type !== 'free') {
          temporary_cart.shipping_radius = null
        }
      }

      if (temporary_cart.item.length > 0) {
        try {
          this.isLoadingCalculation = true
          const { data } = await orderCalculation(temporary_cart, {
            globalLoading: false
          })
          this.data_calculation = data.data
          this.dataInCart = data_cart
          this.dataInCart.total = Number(this.data_calculation.total)
          this.$store.dispatch('saveDataCart', this.dataInCart)
          return true
        } catch (e) {
          this.$store.dispatch('showAlert', {
            type: 'error',
            show: true,
            message: e.message
          })
          if (e.error_tagging === 'DISCOUNT_VOUCHER_INVALID') {
            delete this.dataInCart.discount_voucher_id
            delete this.dataInCart.discount_voucher_detail
            this.$store.dispatch('saveDataCart', this.dataInCart)
            await this.calculation()
          }
          if (e.error_tagging === 'ERR_TRX_NOT_ACCEPT_FREE_SHIPPING' || e.error_tagging === 'DISCOUNT_MAX_DISTANCE') {
            this.isFreeShippingNotValid = true
            this.$store.commit('REMOVE_TEMPORARY_SHIPPING')
            this.dataInCart.remark = 'DELIVERY'
            this.dataInCart.without_shipping = 1
            delete this.dataInCart.shipping_courier_id
            delete this.dataInCart.shipping_service_type
            await this.$store.dispatch('saveDataCart', this.dataInCart)
            await this.calculation()
          }
          return false
        } finally {
          this.isLoadingCalculation = false
        }
      } else {
        this.data_calculation = {}
        this.dataInCart.total = 0
        this.$store.dispatch('saveDataCart', this.dataInCart)
      }
    },
    onScroll(e) {
      if (this.settingScroll === false) {
        if ((e.target.scrollHeight - e.target.scrollTop) <= e.target.offsetHeight) {
          this.getVoucher()
        }
      }
    },
    searchVoucher: debounce(async function(val) {
      if (val === null) {
        val = ''
      }
      this.search_text = val
      this.vouchers = []
      if (val !== '') {
        this.settingScroll = true
        this.params.page = 1
        this.params.per_page = 20
        this.searching = true
        await this.loadDataSearch()
      } else {
        this.settingScroll = true
        this.params.page = 1
        this.params.per_page = 20
        this.searching = false
        await this.getVoucher()
      }
    }, 1000),
    async clearSearchData() {
      await this.searchVoucher()
    },
    async loadDataSearch() {
      if (this.searching === true && this.search_text !== null) {
        try {
          const params = {
            page: this.params.page++,
            per_page: this.params.per_page,
            'search_column[0]': 'title',
            'search_text[0]': this.search_text,
            'search_column[1]': 'code',
            'search_text[1]': this.search_text,
            search_condition: 'OR'
          }
          const { data } = await listVoucher(params)
          this.settingScroll = (data.data.length < this.params.per_page)
          for (var i in data.data) {
            this.vouchers.push(data.data[i])
          }
        } catch (e) {
          this.vouchers = []
          if (e.statusCode !== 404) {
            this.$store.dispatch('showAlert', {
              type: 'error',
              show: true,
              message: e.message
            })
          }
        }
      }
    },
    async getVoucher() {
      var _params = {
        page: this.params.page++,
        per_page: this.params.per_page
      }
      try {
        const { data } = await listVoucher(_params)
        this.settingScroll = (data.data.length < this.params.per_page)
        this.totalVoucher = data.meta.total
        for (var i in data.data) {
          this.vouchers.push(data.data[i])
        }
      } catch (e) {
        this.vouchers = []
        if (e.statusCode !== 404) {
          this.$store.dispatch('showAlert', {
            type: 'error',
            show: true,
            message: e.message
          })
        }
      }
    },
    selectVoucher(idx, item) {
      if (this.selected_voucher_idx === idx) {
        this.selected_voucher_idx = null
        this.selected_voucher = {}
      } else {
        this.selected_voucher_idx = idx
        this.selected_voucher = item
      }
    },
    async setVoucher(type = 'voucher') {
      if (this.totalProductChecked < 1) {
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: this.$t('order.no_products_selected')
        })
        return
      }
      this.$set(this.dataInCart, 'discount_voucher_id', this.selected_voucher.id)
      this.$set(this.dataInCart, 'discount_voucher_detail', this.selected_voucher)
      await this.calculation()
      if (type === 'voucher') {
        this.dialog.voucher = false
      } else if (type === 'detail-voucher') {
        this.voucher_detail = {}
        if (this.dialog.voucher) {
          this.dialog.voucher = false
        }
        this.dialog.voucher_detail = false
      }
    },
    async removeVoucher() {
      this.selected_voucher_idx = null
      this.selected_voucher = {}
      this.$set(this.dataInCart, 'discount_voucher_id', this.selected_voucher.id)
      this.$set(this.dataInCart, 'discount_voucher_detail', this.selected_voucher)
      await this.calculation()
    },
    swipeDown(direction, type = 'voucher') {
      if (direction === 'Down') {
        if (type === 'voucher') {
          this.dialog.voucher = false
        } else if (type === 'voucher_detail') {
          this.dialog.voucher_detail = false
        } else {
          this.dialog.shipping = false
        }
      }
    },
    openDialogShipping() {
      if (this.dataInCart.shipping_address === '') {
        this.openDialogAddress('btnShipping')
      } else {
        this.dialog.shipping = true
        this.getShipper()
        // this.getShipping()
      }
    },
    deleteShipping() {
      // this.$delete(this.dataInCart, 'shipping_detail')
      // this.$set(this.dataInCart, 'without_shipping', 1)
      // await this.calculation()
      this.$store.commit('REMOVE_TEMPORARY_SHIPPING')
      this.dataInCart.remark = 'DELIVERY'
      this.dataInCart.without_shipping = 1
      delete this.dataInCart.shipping_courier_id
      delete this.dataInCart.shipping_service_type
      delete this.dataInCart.shipping_insurance
      this.$store.dispatch('saveDataCart', this.dataInCart)
      this.insurance = false
    },
    async checkShipping(item) {
      if (this.totalProductChecked < 1) {
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: this.$t('order.no_products_selected')
        })
        return
      }
      this.temporary_shipping = item
      this.insurance = false
      this.isLoadingCheckShipper = true
      const isCalcSuccess = await this.calculation()
      this.isLoadingCheckShipper = false
      if (isCalcSuccess) {
        this.dialog.shipping = false
        this.dialog.subshipping = false
      }
    },
    async deleteChecked() {
      const itemsToRemove = this.dataInCart.item.filter(item => item.checked)
      if (itemsToRemove.length === 0) {
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: this.$t('order.no_products_selected')
        })
        return
      }

      for (const item of itemsToRemove) {
        const index = this.dataInCart.item.indexOf(item)
        this.dataInCart.item.splice(index, 1)
      }

      this.updateDataCart()
      this.calculation()
    },
    openVoucherDetail(index) {
      this.voucher_detail = this.vouchers[index]
      this.dialog.voucher_detail = true
    },
    async handleClickItemCart(product, index) {
      try {
        let res
        const productType = product.product_id ? 'p' : 'c'
        if (productType === 'p') {
          res = await getProductDetail(product.product_id)
        } else {
          res = await getProductComboDetail(product.product_combo_id)
        }
        this.openDetailItemCart = true
        this.dataDetailItemCart = res.data.data
        this.selectedItemCart = product
        this.dataDetailItemCart_index = index
        this.dataDetailItemCart_type = productType
      } catch (error) {
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: error.message
        })
      }
    },
    handleCloseDetailItemCart() {
      this.openDetailItemCart = false
      this.selectedItemCart = null
      this.dataDetailItemCart = null
      this.dataDetailItemCart_type = 'p'
      this.dataDetailItemCart_index = null
      this.$refs.refDetailItemCart.handleResetState()
    },
    async handleDeleteProductOnDetailItem(index, autoClose = true) {
      try {
        await this.handleProcessDeleteItem(index)
        if (autoClose) {
          this.handleCloseDetailItemCart()
        }
      } catch (error) {
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: this.$t('dialog.failed_to_delete')
        })
      }
    },
    handleOpenDialogDeleteProductOnDetailItem(index) {
      this.dialog.remove = true
      this.remove_item_idx = index
    },
    async handleSaveChangeDetailItem(updatedItem, index, type = 'product') {
      const LOCAL_DATA_CART = localStorage.getItem(process.env.VUE_APP_COOKIE_KEY + '_data_cart')
      if (LOCAL_DATA_CART) {
        this.dataInCart = JSON.parse(LOCAL_DATA_CART)
      }
      if (type === 'product') {
        // Cek apakah current item yang diedit berbeda dengan data yang lain
        const existingItem = this.dataInCart.item[index]
        const isDifferentItem = existingItem.product_id !== updatedItem.product_id || existingItem.product_variant_id !== updatedItem.product_variant_id
        const hasDifferentAddons = !this.$isEqual(existingItem.addons, updatedItem.addons)

        if (isDifferentItem || hasDifferentAddons) {
        // Jika berbeda, cek apakah item yang sama dengan updatedItem sudah ada di keranjang
          const duplicateItemIndex = this.dataInCart.item.findIndex((e, i) => {
            return i !== index && e.product_id === updatedItem.product_id && e.product_variant_id === updatedItem.product_variant_id && this.$isEqual(e.addons, updatedItem.addons)
          })

          if (duplicateItemIndex !== -1) {
          // Jika ada item yang sama di keranjang, gabungkan qty dan subtotal
            this.dataInCart.item[duplicateItemIndex].qty += updatedItem.qty
            this.dataInCart.item[duplicateItemIndex].sub_total += updatedItem.sub_total

            // Hapus item yang sedang diedit karena sudah digabungkan dengan item lain
            this.dataInCart.item.splice(index, 1)
          } else {
          // Jika tidak ada item yang sama di keranjang, update item yang sedang diedit
            this.dataInCart.item[index] = { ...updatedItem }
          }
        } else {
          // Jika sama, update kuantitas dan subtotal item yang sedang diedit
          this.dataInCart.item[index] = { ...updatedItem }
          // this.dataInCart.item[index].qty = updatedItem.qty
          // this.dataInCart.item[index].checked = updatedItem.checked
          // this.dataInCart.item[index].sub_total = updatedItem.sub_total
          // this.dataInCart.item[index].min_order = updatedItem.min_order
          // this.dataInCart.item[index].max_order = updatedItem.max_order
        }
      } else {
        this.dataInCart.item[index] = { ...updatedItem }
      }
      await this.handleCloseDetailItemCart()
      await this.calculation()
    },
    async getShipper() {
      try {
        const getCheckedItem = this.dataInCart.item.filter((item) => item.checked)
        const payloadCart = { ...this.dataInCart }
        payloadCart.item = getCheckedItem
        if (getCheckedItem.length < 1) {
          this.list_shipper = []
          return
        }
        const { data } = await shipperList(payloadCart)
        const shipperSupport = ['Instant', 'Same Day', 'Regular', 'Express', 'Cargo']
        const getData = data.data.filter(shipper => {
          if (shipperSupport.includes(shipper.name)) {
            if (shipper.item.length > 0) {
              return shipper
            }
          }
        })
        this.list_shipper = getData
      } catch (e) {
        this.list_shipper = []
        if (e.statusCode !== 404) {
          this.$store.dispatch('showAlert', {
            type: 'error',
            show: true,
            message: e.message
          })
        }
      }
    },
    async checkShipper(item) {
      if (this.totalProductChecked < 1) {
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: this.$t('order.no_products_selected')
        })
        return
      }
      const objShipper = {
        name: item.logistic_name,
        type: item.rate_type,
        shipping_courier_id: item.rate_id,
        shipping_service_type: item.rate_type,
        shipment_method: item.rate_type,
        shipment_working_hour: item.working_hour,
        price: item.final_price,
        logo: item.image_url,
        must_use_insurance: item.must_use_insurance,
        insurance_fee: item.insurance_fee,
        insurance_details: item.insurance_details,
        insurance_included: item.insurance_included
      }
      this.temporary_shipping = objShipper
      // Asuransi
      this.insurance = false
      if (item.insurance_included || item.must_use_insurance) {
        this.insurance = true
      }
      // End Asuransi
      this.isLoadingCheckShipper = true
      const isCalcSuccess = await this.calculation()
      this.isLoadingCheckShipper = false
      if (isCalcSuccess) {
        this.dialog.shipping = false
        this.dialog.subshipping = false
      }
    },
    courier_times_on_close(item) {
      const result = {
        type: 'close',
        description: ''
      }
      if (item || !this.$isEmpty(item)) {
        const shipping = item
        const workingHour = shipping.time_oprational
        if (Array.isArray(workingHour) && workingHour.length < 1) {
          result.type = 'open'
          result.description = `24 ${this.$t('order.hour')}`
          return result
        }
        const time = workingHour
        const getClosingTime = this.$closingTime({ endTime: time.close_time })
        result.type = getClosingTime.type
        if (getClosingTime.type === 'close' && getClosingTime.type === 'open') {
          result.description = this.$t('order.courier_available', { time: `${time.open_time} - ${time.close_time}` })
          return result
        } else if (getClosingTime.type === 'closing') {
          result.description = this.$t('order.courier_close_in', { time: Math.round(getClosingTime.closingTime) })
          return result
        } else {
          result.description = this.$t('order.courier_available', { time: `${time.open_time} - ${time.close_time}` })
          return result
        }
      }
      return result
    },
    stringJoinValidVoucherFor(data, key = 'name') {
      if (data && data.length > 0) {
        return data.map((obj) => obj[key]).join(', ')
      }
    },
    handleUseInsurance() {
      this.calculation()
    },
    handleOpenDetailInsurance() {
      this.dialog.detailInsurance = true
    },
    handleCloseDetailInsurance() {
      this.dialog.detailInsurance = false
    },
    async getShipperTnC(shipper) {
      try {
        const res = await shipperTnC({
          courier_id: shipper.courier_id,
          courier_type: shipper.rate_type
        })
        this.dataShipperTnC.subShipping = shipper
        this.dataShipperTnC.terms = res.data.data.term
        this.dialog.tncshipper = true
      } catch (e) {
        if (e.statusCode === 404) {
          this.$store.dispatch('showAlert', {
            type: 'success',
            show: true,
            message: e.message
          })
        } else {
          this.$store.dispatch('showAlert', {
            type: 'error',
            show: true,
            message: e.message
          })
        }
      }
    },
    async handleOpenTncShipper(shipper) {
      await this.getShipperTnC(shipper)
    },
    handleCloseTncShipper() {
      this.dialog.tncshipper = false
    }
  }
}
</script>
