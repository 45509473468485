<template lang="html">
  <div>
    <v-app-bar app flat color="white" class="app-bar-no-padding">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="d-flex align-center">
              <v-btn icon class="mr-2" @click="goBack">
                <v-icon>
                  mdi-arrow-left
                </v-icon>
              </v-btn>
              <div class="font-18 font-weight-bold">
                {{ $t('product.detail_product') }}
              </div>
              <v-spacer />
              <v-btn icon height="40" width="40" class="ml-auto mr-2" @click="goToCart()">
                <v-badge v-if="stockInCart > 0" color="red" :content="stockInCart">
                  <v-icon color="#0085CD">
                    mdi-cart
                  </v-icon>
                </v-badge>
                <v-icon v-else color="#0085CD">
                  mdi-cart
                </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>
    <v-container>
      <v-row>
        <v-col cols="12" sm="6" md="8">
          <v-card elevation="0">
            <div v-if="image_product.length > 0">
              <v-carousel id="product-slider" :show-arrows="false" :height="$vuetify.breakpoint.width < 600 ? 392 : 650" hide-delimiter-background continuous class="carousel--item-product">
                <v-carousel-item v-for="(item, key) in image_product" :key="key" reverse-transition="fade-transition" transition="fade-transition">
                  <v-img :src="item" max-width="800" :aspect-ratio="1/1" :contain="true" />
                </v-carousel-item>
              </v-carousel>
            </div>
            <div class="pt-3">
              <div class="font-24 font-weight-bold pb-2 blue--text d-flex flex-grow-1 d-sm-none">
                <div v-mask-money="price" class="mr-2" />
                <div v-if="market_price > 0" class="grey--text text-decoration-line-through pr-1">
                  <span v-mask-money-no-currency="market_price" />
                </div>
              </div>
              <div class="font-18 font-weight-medium line-height-normal">
                {{ detail_product.item.name }}
              </div>
              <div class="font-12 grey--text mb-4 line-height-normal">
                {{ detail_product.item.category_name + ' - ' + detail_product.item.klasifikasi }}
              </div>
            </div>
            <div v-if="vouchers.length > 0" class="product-detail-discount-container">
              <span>
                Voucher
              </span>
              <div class="d-flex align-center flex-wrap" style="gap: 8px">
                <div v-for="(item, idx) in vouchers" :key="idx" class="product-detail-discount">
                  <div v-if="item.fdiscount_rate !== '0'">
                    {{ $decimalToPercentage(item.discount_rate, true) }}
                  </div>
                  <div v-else>
                    <span v-mask-money="item.discount_amount" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="detail_product.item.variants && detail_product.item.variants.length > 0" class="d-sm-none">
              <div class="d-flex">
                <div class="wrap-variant-list">
                  <div v-for="(item, idx) in detail_product.item.variants" :key="idx" :class="[`col-variant`, { 'active': form.product_variant_id === item.id }]" @click="selectVariant(item)">
                    <div class="variant-iamge">
                      <v-img :src="item.photo_lg" height="64px" width="64px" />
                    </div>
                    <div class="variant-content">
                      <div class="variant-title">
                        {{ item.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-sm-none">
              <div class="maxmin-product mb-4">
                <div class="wrap-maxmin-product">
                  <div class="content-maxmin">
                    <div class="title-content-maxmin d-flex" v-html="textInfoStock" />
                    <div v-if="Number(detail_product.item.min_order) > 0 || Number(detail_product.item.max_order) > 0 && Number(detail_product.item.max_order) >= Number(detail_product.item.min_order)" class="subtitle-content-maxmin">
                      <span v-if="Number(detail_product.item.min_order) > 0">
                        {{ $t('product.min_buy') + Number(detail_product.item.min_order) }}
                      </span>
                      <span v-if="Number(detail_product.item.min_order) > 0 && Number(detail_product.item.max_order) > 0 && Number(detail_product.item.max_order) >= Number(detail_product.item.min_order)">
                        -
                      </span>
                      <span v-if="Number(detail_product.item.max_order) > 0 && Number(detail_product.item.max_order) >= Number(detail_product.item.min_order)">
                        {{ $t('product.max_buy') + Number(detail_product.item.max_order) }}
                      </span>
                    </div>
                  </div>
                  <div class="input-maxmin">
                    <!-- <v-text-field
                      v-model="form.qty"
                      hide-details
                      dense
                      outlined
                      append-icon="mdi-plus"
                      prepend-inner-icon="mdi-minus"
                      type="number"
                      hide-spin-buttons
                      oninput="if(this.value < 0) this.value = 0;"
                      @click:append="!disabled_plus && plus()"
                      @click:prepend-inner="!disabled_minus && minus()"
                    /> -->
                    <v-text-field v-model.number="form.qty" hide-details dense outlined oninput="if(!this.value) this.value = 0;" @keydown="$filterInputNumber" @input="inputQty">
                      <template v-slot:append-outer>
                        <v-btn icon :disabled="disabled_plus" @click="plus()">
                          <v-icon>
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:prepend>
                        <v-btn icon :disabled="disabled_minus" @click="minus()">
                          <v-icon>
                            mdi-minus
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </div>
                </div>
              </div>
            </div>
            <div :class="[productRelated.length > 0 ? 'mb-4' : 'margin-product']">
              <div class="font-18 black--text font-weight-medium pb-2">
                {{ $t('product.description') }}
              </div>
              <div class="d-flex">
                <div class="wrap-desc">
                  <div v-if="detail_product.item.description" v-html="description_html" />
                  <div v-else class="grey--text">
                    {{ $t('product.no_description') }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="productRelated.length > 0" class="mb-4">
              <list-product :title="$t('product.related_product')" :products="productRelated" is-related :func-show="showDetail" />
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="4" class="d-none d-sm-block">
          <div class="col-detail-product">
            <div class="mb-4">
              <div class="maxmin-product mb-4 d-flex">
                <div class="wrap-maxmin-product">
                  <div class="content-maxmin">
                    <div class="title-content-maxmin d-flex" v-html="textInfoStock" />
                    <div v-if="Number(detail_product.item.min_order) > 0 || Number(detail_product.item.max_order) > 0 && Number(detail_product.item.max_order) >= Number(detail_product.item.min_order)" class="subtitle-content-maxmin">
                      <span v-if="Number(detail_product.item.min_order) > 0">
                        {{ $t('product.min_buy') + Number(detail_product.item.min_order) }}
                      </span>
                      <span v-if="Number(detail_product.item.min_order) > 0 && Number(detail_product.item.max_order) > 0 && Number(detail_product.item.max_order) >= Number(detail_product.item.min_order)">
                        -
                      </span>
                      <span v-if="Number(detail_product.item.max_order) > 0 && Number(detail_product.item.max_order) >= Number(detail_product.item.min_order)">
                        {{ $t('product.max_buy') + Number(detail_product.item.max_order) }}
                      </span>
                    </div>
                  </div>
                  <div class="input-maxmin">
                    <v-text-field v-model.number="form.qty" hide-details dense outlined oninput="if(!this.value) this.value = 0;" @keydown="$filterInputNumber" @input="inputQty">
                      <template v-slot:append-outer>
                        <v-btn icon :disabled="disabled_plus" @click="plus()">
                          <v-icon>
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:prepend>
                        <v-btn icon :disabled="disabled_minus" @click="minus()">
                          <v-icon>
                            mdi-minus
                          </v-icon>
                        </v-btn>
                      </template>
                    </v-text-field>
                  </div>
                </div>
              </div>
              <div v-if="detail_product.item.variants && detail_product.item.variants.length > 0" class="d-flex">
                <div class="d-flex">
                  <div class="wrap-variant-list">
                    <div v-for="(item, idx) in detail_product.item.variants" :key="idx" :class="[`col-variant`, { 'active': form.product_variant_id === item.id }]" @click="selectVariant(item)">
                      <div class="variant-iamge">
                        <v-img :src="item.photo_lg" height="64px" width="64px" />
                      </div>
                      <div class="variant-content">
                        <div class="variant-title">
                          {{ item.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="pb-2 flex-grow-1 d-flex align-end">
                <div class="font-16 grey--text">
                  {{ $t('product.price') }}
                </div>
                <v-spacer />
                <div class="text-right">
                  <div v-if="market_price > 0" class="font-14 grey--text text-decoration-line-through">
                    <span v-mask-money-no-currency="market_price" />
                  </div>
                  <div v-mask-money="price" class="blue--text font-weight-bold font-20" />
                </div>
              </div>
            </div>
            <div class="d-flex">
              <div class="flex-grow-1">
                <v-btn color="primary" depressed block large class="text-none font-16 mb-3" @click="addToCart(0)">
                  {{ $t('product.add') + ' ' + form.qty }}
                </v-btn>
                <v-btn outlined color="primary" block large class="text-none font-16" @click="addToCart(1)">
                  {{ $t('product.buy_now') }}
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="float-button-cart d-sm-none">
      <div class="wrap-float-button-cart">
        <div class="row-float-button-cart">
          <div class="float-button-6 float-button-cart-left">
            <v-btn block height="40" class="border-radius-5 btn-fload-cart-add text-none font-16 font-weight-700" @click="addToCart(0)">
              {{ $t('product.add') + ' ' + form.qty }}
            </v-btn>
          </div>
          <div class="float-button-6 float-button-cart-right">
            <v-btn block height="40" color="primary" class="border-radius-5 btn-fload-cart-add text-none font-16 font-weight-700" @click="addToCart(1)">
              {{ $t('product.buy_now') }}
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import { debounce } from 'debounce'
import ListProduct from '@/components/product/ListProduct'
import { listVoucher } from '@/api/voucher'
export default {
  name: 'ProductDetail',
  components: {
    ListProduct
  },
  props: {
    value: {
      default() {
        return {
          type: 'product',
          item: {}
        }
      },
      type: Object
    },
    clickAddcart: {
      required: true,
      type: Function
    },
    dataInCart: {
      default() {
        return {}
      },
      type: Object
    }
  },
  data() {
    return {
      cart: {
        item: []
      },
      form: {
        image: '',
        addons: [],
        hold_qty: 0,
        is_out_stock: 0,
        klasifikasi_id: null,
        name: '',
        non_service_charge: 0,
        non_taxable: 0,
        note: '',
        product_combo_id: null,
        product_combo_items: [],
        product_id: null,
        product_variant_id: null,
        product_variant_name: null,
        qty: 1,
        sell_price: 0,
        stock_qty: 0,
        sub_total: 0,
        track_inventory: 0,
        weight: 0,
        fweight: '',
        checked: false,
        min_order: 0,
        max_order: 0
      },
      item_addons: [],
      unavailable_product: '',
      vouchers: []
    }
  },
  computed: {
    productRelated() {
      return this.detail_product.related
    },
    textInfoStock() {
      if (this.detail_product.item) {
        const text = this.$textInfoProductStock(this.detail_product.item, this.form)
        return text
      }
      return ''
    },
    'detail_product': {
      get() {
        this.setDefaultFormQty(this.value.item)
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    description_html() {
      if (this.detail_product.item.description) {
        return (this.detail_product.item.description).replace(/\n/g, '<br />')
      } else {
        return ''
      }
    },
    image_product() {
      const images = []
      const detail_product = this.detail_product.item
      if (this.form.product_variant_id) {
        const idx = detail_product.variants.findIndex((e) => e.id === this.form.product_variant_id)
        if (idx !== -1) {
          if (detail_product.variants[idx].all_photos && detail_product.variants[idx].all_photos.length > 0) {
            detail_product.variants[idx].all_photos.map((item) => {
              images.push(item.photo_lg)
            })
          } else {
            images.push(detail_product.variants[idx].photo_lg)
          }
        } else {
          detail_product.all_photos.map((item) => {
            images.push(item.photo_lg)
          })
        }
      } else {
        if (detail_product.all_photos && detail_product.all_photos.length > 0) {
          detail_product.all_photos.map((item) => {
            images.push(item.photo_lg)
          })
        } else {
          images.push(detail_product.photo_lg)
        }
      }
      return images
    },
    price() {
      const detail_product = this.detail_product.item
      const price = this.$getProductPrice(detail_product, 'sell_price', this.form.product_variant_id)
      return Number(price)
    },
    market_price() {
      const detail_product = this.detail_product.item
      const price = this.$getProductPrice(detail_product, 'market_price', this.form.product_variant_id)
      return Number(price)
    },
    stockInCart() {
      if (this.dataInCart.item.length > 0) {
        const uniqueIds = []
        const unique = this.dataInCart.item.filter(element => {
          const isDuplicate = uniqueIds.includes(element.product_id)
          if (!isDuplicate) {
            uniqueIds.push(element.product_id)
            return true
          }
        })
        return unique.length
      } else {
        return 0
      }
    },
    disabled_plus() {
      let disabled = false
      const detail_product = this.detail_product.item
      let stock_in_cart = 0
      if (detail_product.has_variant) {
        const elementId = this.form.product_variant_id
        const stock_incart = this.$stockCart(elementId, 'variant')
        stock_in_cart = stock_in_cart + stock_incart
      } else {
        const stock_incart = this.$stockCart(detail_product.id)
        stock_in_cart = stock_in_cart + stock_incart
      }
      if (Number(detail_product.max_order) > 0 && (this.form.qty + stock_in_cart) >= Number(detail_product.max_order)) {
        disabled = true
      }
      return disabled
    },
    disabled_minus() {
      let disabled = false
      const detail_product = this.detail_product.item
      if (Number(detail_product.min_order) <= 0 && this.form.qty === 1) {
        disabled = true
      } else if (Number(detail_product.min_order) > 0 && this.form.qty <= Number(detail_product.min_order)) {
        disabled = true
      }
      return disabled
    },
    sub_total() {
      let total_addons = 0
      if (this.item_addons.length > 0) {
        for (let i = 0; i < this.item_addons.length; i++) {
          if (this.item_addons[i]) {
            total_addons = total_addons + Number(this.detail_product.item.addons[i].price)
          }
        }
      }
      return (Number(this.price) + total_addons) * this.form.qty
    },
    weight() {
      const detail_product = this.detail_product.item
      const result = this.$getProductWeight(detail_product, this.form)
      return result
    }
  },
  mounted() {
    const LOCAL_DATA_CART = localStorage.getItem(process.env.VUE_APP_COOKIE_KEY + '_data_cart')
    if (LOCAL_DATA_CART) {
      this.cart = JSON.parse(LOCAL_DATA_CART)
    }
    if (this.$store.state.global.customer && !this.$isEmpty(this.$store.state.global.customer)) {
      this.getVoucher()
    }
  },
  methods: {
    goToCart() {
      this.$router.push({ name: 'shopping_cart' }).catch(() => {})
    },
    showDetail(item) {
      this.$router.push({ name: 'product_detail', params: { id: item.id }}).catch(e => {})
    },
    goBack() {
      this.$router.push({ name: 'home' })
    },
    plus() {
      this.form.qty = Number(this.form.qty) + 1
      const dataProduct = this.$validationProduct(this.detail_product.type, this.detail_product.item, this.form)
      if (!dataProduct.valid) {
        if (dataProduct.status_type !== 'error_qty_min') {
          this.form.qty = Number(this.form.qty) - 1
          this.$store.dispatch('showAlert', {
            type: 'error',
            show: true,
            message: dataProduct.message
          })
          return
        }
      }
    },
    minus() {
      this.form.qty = Number(this.form.qty) - 1
    },
    selectVariant(item) {
      if (this.form.product_variant_id === item.id) return
      this.form.product_variant_id = item.id
      this.form.product_variant_name = item.name
      this.setDefaultFormQty(this.detail_product.item)
    },
    /*
    * depreceted
    validateData(type = 'product') {
      const detail_product = this.detail_product.item
      const data = {
        valid: true,
        message: '',
        hold_qty: Number(detail_product.hold_qty),
        is_out_stock: detail_product.is_out_stock,
        stock_qty: Number(detail_product.stock_qty),
        image: detail_product.photo_lg
      }

      const minOrder = Number(detail_product.min_order)
      const maxOrder = Number(detail_product.max_order)
      let stock_in_cart = this.form.qty
      if (stock_in_cart <= 0) {
        data.valid = false
        data.message = this.$t('product.invalid_purchase_qty_min')
        return data
      }
      if (minOrder > 0 && maxOrder > 0) {
        // Jika minOrder dan maxOrder lebih dari 0, cek stock_in_cart dengan keduanya
        stock_in_cart += this.$getStockInCart(detail_product, this.form)
        if (stock_in_cart < minOrder || stock_in_cart > maxOrder) {
          data.valid = false
          data.message = stock_in_cart < minOrder ? this.$t('product.invalid_purchase_qty_min') : this.$t('product.invalid_purchase_qty_max')
          return data
        }
      } else if (minOrder > 0 && maxOrder < 1) {
        // Jika minOrder lebih dari 0 dan maxOrder lebih kecil dari 1, cek stock_in_cart dengan minOrder saja
        stock_in_cart += this.$getStockInCart(detail_product, this.form)
        if (stock_in_cart < minOrder) {
          data.valid = false
          data.message = this.$t('product.invalid_purchase_qty_min')
          return data
        }
      } else if (maxOrder > 0 && minOrder < 1) {
        // Jika maxOrder lebih dari 0 dan minOrder lebih kecil dari 1, cek stock_in_cart dengan maxOrder saja
        stock_in_cart += this.$getStockInCart(detail_product, this.form)
        if (stock_in_cart > maxOrder) {
          data.valid = false
          data.message = this.$t('product.invalid_purchase_qty_max')
          return data
        }
      }

      if (type === 'product') {
        if (detail_product.track_inventory) {
          if (detail_product.has_variant) {
            if (!this.form.product_variant_id) {
              data.valid = false
              data.message = this.$t('product.select_variant_first')
            } else {
              const stock_incart = this.$stockCart(this.form.product_variant_id, 'variant')
              const idx = detail_product.variants.findIndex((e) => e.id === this.form.product_variant_id)
              data.valid = Number(detail_product.variants[idx].stock_qty) - Number(detail_product.variants[idx].hold_qty) >= (Number(this.form.qty) + stock_incart) && !detail_product.variants[idx].is_out_stock
              data.message = this.$t('product.stock_is_not_enough', { data: detail_product.variants[idx].name })
              data.hold_qty = Number(detail_product.variants[idx].hold_qty)
              data.is_out_stock = detail_product.variants[idx].is_out_stock
              data.stock_qty = Number(detail_product.variants[idx].stock_qty)
              data.image = detail_product.variants[idx].photo_lg
            }
          } else {
            const stock_incart = this.$stockCart(detail_product.id)
            data.valid = Number(detail_product.stock_qty) - Number(detail_product.hold_qty) >= (Number(this.form.qty) + stock_incart) && !detail_product.is_out_stock
            data.message = this.$t('product.stock_is_not_enough', { data: detail_product.name })
          }
        } else {
          if (detail_product.has_variant) {
            if (!this.form.product_variant_id) {
              data.valid = false
              data.message = this.$t('product.select_variant_first')
            } else {
              const idx = detail_product.variants.findIndex((e) => e.id === this.form.product_variant_id)
              data.valid = !detail_product.variants[idx].is_out_stock
              data.message = this.$t('product.stock_is_not_enough', { data: detail_product.variants[idx].name })
              data.hold_qty = Number(detail_product.variants[idx].hold_qty)
              data.is_out_stock = detail_product.variants[idx].is_out_stock
              data.stock_qty = Number(detail_product.variants[idx].stock_qty)
              data.image = detail_product.variants[idx].photo_lg
            }
          } else {
            data.valid = !detail_product.is_out_stock
            data.message = this.$t('product.stock_is_not_enough', { data: detail_product.name })
          }
        }
      } else {
        if (this.form.product_combo_items.length > 0 && this.detail_product.item.items.length === this.form.product_combo_items.length) {
          for (let i = 0; i < this.form.product_combo_items.length; i++) {
            if (typeof this.form.product_combo_items[i] === 'undefined') {
              data.valid = false
              data.message = this.$t('v2.complete_the_required_fields')
              break
            } else {
              data.hold_qty = 0
              data.is_out_stock = this.detail_product.item.is_out_stock
              data.stock_qty = Number(this.detail_product.item.stock_qty)
              if (this.form.product_combo_items[i].published) {
                if (this.form.product_combo_items[i].track_inventory) {
                  if (this.form.product_combo_items[i].variant_id !== '') {
                    const stock_incart = this.$stockCart(this.form.product_combo_items[i].variant_id, 'variant')
                    data.valid = Number(this.form.product_combo_items[i].stock_qty) - Number(this.form.product_combo_items[i].hold_qty) >= ((Number(this.form.qty) * this.form.product_combo_items[i].qty) + stock_incart) && !this.form.product_combo_items[i].is_out_stock
                    data.message = this.$t('product.stock_is_not_enough', { data: this.form.product_combo_items[i].name })
                    if (data.valid === false) {
                      break
                    }
                  } else {
                    const stock_incart = this.$stockCart(this.form.product_combo_items[i].id)
                    data.valid = Number(this.form.product_combo_items[i].stock_qty) - Number(this.form.product_combo_items[i].hold_qty) >= ((Number(this.form.qty) * this.form.product_combo_items[i].qty) + stock_incart) && !this.form.product_combo_items[i].is_out_stock
                    data.message = this.$t('product.stock_is_not_enough', { data: this.form.product_combo_items[i].name })
                    if (data.valid === false) {
                      break
                    }
                  }
                } else {
                  data.valid = !this.form.product_combo_items[i].is_out_stock
                  data.message = this.$t('product.stock_is_not_enough', { data: this.form.product_combo_items[i].name })
                  if (data.valid === false) {
                    break
                  }
                }
              } else {
                data.valid = false
                data.message = this.$t('v2.products_have_not_been_published', { item: this.form.product_combo_items[i].name })
                break
              }
            }
          }
        } else {
          data.valid = false
          data.message = this.$t('v2.complete_the_required_fields')
        }
      }
      return data
    },
    */
    // type, 0 untuk add to cart saja dan 1 untuk langsung direct ke halaman cart
    async addToCart(type = 0) {
      const dataProduct = await this.$validationProduct(this.detail_product.type, this.detail_product.item, this.form)
      const detail_product = this.detail_product.item
      if (!dataProduct.valid) {
        this.$store.dispatch('showAlert', {
          type: 'error',
          show: true,
          message: dataProduct.message
        })
        return
      } else {
        this.form.qty = Number(this.form.qty)
        this.form.hold_qty = dataProduct.hold_qty
        this.form.is_out_stock = dataProduct.is_out_stock
        this.form.stock_qty = dataProduct.stock_qty
        this.form.name = detail_product.name
        this.form.non_taxable = detail_product.non_taxable
        this.form.non_service_charge = typeof detail_product.non_service_charge !== 'undefined' ? detail_product.non_service_charge : 0
        if (this.detail_product.type === 'product') {
          this.form.product_id = detail_product.id
          this.form.klasifikasi_id = detail_product.klasifikasi_id
          this.form.image = dataProduct.image
        } else {
          this.form.product_combo_id = detail_product.id
          this.form.klasifikasi_id = null
          this.form.image = dataProduct.image
        }
        this.form.sell_price = this.price
        this.form.sub_total = this.sub_total
        this.form.track_inventory = detail_product.track_inventory
        this.form.weight = this.weight.weight
        this.form.fweight = this.weight.fweight
        this.form.max_order = Number(detail_product.max_order)
        this.form.min_order = Number(detail_product.min_order)
        if (type === 1) {
          this.form.checked = true
        } else {
          this.form.checked = false
        }
        await this.clickAddcart(this.form, this.detail_product.type, this.detail_product.method)
        if (type === 1) {
          this.$router.push({ name: 'shopping_cart' })
        }
      }
    },
    async getVoucher() {
      try {
        const { data } = await listVoucher()
        for (var i in data.data) {
          if (data.data[i].valid_for_all_brands === 1 && data.data[i].valid_for_all_products === 1) {
            this.vouchers.push(data.data[i])
          }
        }
      } catch (e) {
        this.vouchers = []
        if (e.statusCode !== 404) {
          this.$store.dispatch('showAlert', {
            type: 'error',
            show: true,
            message: e.message
          })
        }
      }
    },
    setDefaultFormQty(value) {
      const minOrder = Number(value.min_order)
      const maxOrder = Number(value.max_order)
      if (minOrder > 0) {
        if (minOrder === maxOrder) {
          // Jika min order sama dengan max order, ex: min_order: 10, max_order: 10. maka set salah satunya
          this.form.qty = maxOrder
        } else if (minOrder > maxOrder) {
          // Jika min order lebih besar dari max order, ex: min_order: 10 max_order: 2. maka set ke min order
          this.form.qty = minOrder
        } else {
          this.form.qty = minOrder
        }
      } else {
        this.form.qty = 1
      }
    },
    async inputQty(e) {
      const dataProduct = await this.$validationProduct(this.detail_product.type, this.detail_product.item, this.form)
      if (!dataProduct.valid) {
        if (dataProduct.status_type === 'error_qty_min') {
          // this.form.qty = +this.detail_product.item.min_order
        } else if (dataProduct.status_type === 'error_stock_is_not_enough') {
          // this.form.qty = dataProduct.remaining_stock
          this.$store.dispatch('showAlert', {
            type: 'error',
            show: true,
            message: dataProduct.message
          })
        } else if (dataProduct.status_type === 'error_qty_max') {
          this.$store.dispatch('showAlert', {
            type: 'error',
            show: true,
            message: dataProduct.message
          })
        }
      }
    }
  }
}
</script>
